import enumSports from "@/data/enumSports.json";

const getPathSportIconById = (idSport) => {
  if (enumSports[idSport] && enumSports[idSport].icon)
    return "sports/" + enumSports[idSport].icon;
  return "sports/other.svg";
};
const getSportLabel = (idSport) => {
  if (enumSports[idSport] && enumSports[idSport].label)
    return enumSports[idSport].label;
  return "AUTRES";
};

export { getSportLabel, getPathSportIconById };
