<template>
  <div
    class="border-item mb-4"
    :class="item.typeCreneau == 'terrain' ? 'pb-2' : ''"
  >
    <div class="flex items-center mb-4 w-full">
      <div class="w-14 h-14 p-2 sm:w-20 sm:h-20 box-shadow rounded-xl mr-2">
        <img :src="require(`@/assets/icons/utils/sports/${sportIcon}`)" />
      </div>
      <!-- SÉLECTION FA V1 -->
      <div class="flex flex-col w-4/6" v-if="item.typeCreneau === null">
        <div class="flex tracking-wide">
          <DoiText
            :textColor="0"
            :fontFamily="1"
            :fontSize="3"
            class="font-text uppercase"
            >TERRAIN -</DoiText
          >
          <DoiText
            class="font-text uppercase pl-1"
            :textColor="1"
            :fontFamily="1"
            :fontSize="3"
            >{{ item.sport }}</DoiText
          >
        </div>
        <div class="flex">
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="3"
            class="font-text uppercase"
            >{{ date }}</DoiText
          >
        </div>
        <div class="lg:hidden flex items-center justify-between">
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="font-text"
            >{{ item.nbPlaceConseille }} PARTICIPANTS CONSEILLÉS</DoiText
          >
          <DoiText
            v-if="item.nb_place !== 0 && item.tarif !== 0"
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text"
            >PRIX / <span class="material-symbols-outlined">person</span>:
            {{ (item.tarif / 100 / item.nbPlaceConseille).toFixed(2) }}
            &euro;</DoiText
          >
          <DoiText
            v-else
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text"
            >GRATUIT</DoiText
          >
        </div>
        <div class="hidden lg:flex items-center">
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="font-text"
            >{{ item.nbPlaceConseille }} PARTICIPANTS CONSEILLÉS</DoiText
          >
          <DoiText
            v-if="item.nb_place !== 0 && item.tarif !== 0"
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text ml-8"
            >PRIX / <span class="material-symbols-outlined">person</span>:
            {{ (item.tarif / 100 / item.nbPlaceConseille).toFixed(2) }}
            &euro;</DoiText
          >
          <DoiText
            v-else
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text ml-8"
            >GRATUIT</DoiText
          >
        </div>
      </div>

      <!-- SÉLECTION FA V2 -->
      <div class="flex flex-col w-4/6" v-if="item.typeCreneau === 'terrain'">
        <div class="flex tracking-wide">
          <DoiText
            :textColor="0"
            :fontFamily="1"
            :fontSize="3"
            class="font-text uppercase"
            >{{ item.typeCreneau }} -</DoiText
          >
          <DoiText
            class="font-text uppercase pl-1"
            :textColor="1"
            :fontFamily="1"
            :fontSize="3"
            >{{ item.sport }}</DoiText
          >
        </div>
        <div class="flex">
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="3"
            class="font-text uppercase"
            >{{ date }} - {{ formattedSlotDuration(item) }}</DoiText
          >
        </div>
        <div class="lg:hidden flex items-center justify-between">
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="font-text"
            >{{ item.nbPlaceConseille }} PARTICIPANTS CONSEILLÉS</DoiText
          >
          <DoiText
            v-if="item.nb_place !== 0 && item.tarif"
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text"
            >PRIX / <span class="material-symbols-outlined">person</span>:
            {{ (item.tarif / 100 / item.nbPlaceConseille).toFixed(2) }}
            &euro;</DoiText
          >
          <DoiText
            v-else
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text"
            >GRATUIT</DoiText
          >
        </div>
        <div class="hidden lg:flex items-center">
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="font-text"
            >{{ item.nbPlaceConseille }} PARTICIPANTS CONSEILLÉS</DoiText
          >
          <DoiText
            v-if="item.nb_place !== 0 && item.tarif"
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text ml-8"
            >PRIX / <span class="material-symbols-outlined">person</span>:
            {{ (item.tarif / 100 / item.nbPlaceConseille).toFixed(2) }}
            &euro;</DoiText
          >
          <DoiText
            v-else
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="flex items-center font-text ml-8"
            >GRATUIT</DoiText
          >
        </div>
      </div>

      <div
        class="flex w-4/6"
        v-else-if="item.typeCreneau == 'place' || item.typeCreneau == 'cours'"
      >
        <div class="flex flex-col tracking-wide">
          <div class="flex">
            <DoiText
              :textColor="item.typeCreneau == 'place' ? 0 : 8"
              :fontFamily="1"
              :fontSize="3"
              class="font-text uppercase"
              >{{ item.typeCreneau }} -</DoiText
            >
            <DoiText
              class="font-text uppercase pl-1"
              :textColor="1"
              :fontFamily="1"
              :fontSize="3"
              >{{ item.sport }}</DoiText
            >
          </div>
          <div
            class="flex flex-col md:flex-row md:items-center md:justify-between"
          >
            <DoiText
              :textColor="1"
              :fontFamily="1"
              :fontSize="3"
              class="font-text uppercase md:w-1/2"
              >{{ date }} - {{ formattedSlotDuration(item) }}</DoiText
            >
            <input
              v-model="item.quantite"
              @click="modifyCart(item.id)"
              type="number"
              class="bg-cart-item md:w-2/12"
              min="1"
            />
            <DoiText
              v-if="item.tarif > 0"
              :textColor="item.typeCreneau == 'place' ? 0 : 8"
              :fontFamily="1"
              :fontSize="3"
              class="flex items-center justify-end font-text md:w—4/12"
              >PRIX PLACE : {{ item.tarif / 100 }} &euro;</DoiText
            >
            <DoiText
              v-else
              :textColor="item.typeCreneau == 'place' ? 0 : 8"
              :fontFamily="1"
              :fontSize="3"
              class="flex items-center justify-end font-text md:w—4/12"
              >GRATUIT</DoiText
            >
          </div>
        </div>
      </div>

      <div
        v-if="item.typeCreneau == 'terrain' || item.typeCreneau == null"
        class="hidden lg:flex justify-end lg:w-1/6"
      >
        <DoiText
          v-if="item.tarif > 0"
          :textColor="0"
          :fontFamily="0"
          :fontSize="3"
          class="tracking-wide"
          >PRIX TERRAIN : {{ (item.tarif / 100).toFixed(2) }} &euro;</DoiText
        >
        <DoiText
          v-else
          :textColor="0"
          :fontFamily="0"
          :fontSize="3"
          class="tracking-wide"
          >GRATUIT</DoiText
        >
        <!-- prix par personne * nombre de participants -->
      </div>
      <div class="w-1/6 flex justify-end">
        <img
          @click.stop.prevent="deleteElement(item.id)"
          class="cursor-pointer"
          src="@/assets/img/trash.png"
        />
      </div>
    </div>
    <div
      v-if="openIndex === index && item.typeCreneau === 'cours'"
      class="flex flex-col lg:flex-row px-10 lg:px-20 pb-4 items-center"
    >
      <img class="hidden lg:block" :src="item.description.img" />
      <div class="flex flex-col ml-6 mb-4">
        <div class="mb-4">
          <DoiText
            class="font-text uppercase"
            :textColor="1"
            :fontFamily="1"
            :fontSize="3"
            >NIVEAU DU COURS :
            <DoiText
              type="span"
              class="font-text uppercase"
              :textColor="1"
              :fontFamily="0"
              :fontSize="3"
              >{{ item.description.niveau }}
            </DoiText></DoiText
          >
        </div>
        <div>
          <DoiText
            class="font-text uppercase"
            :textColor="1"
            :fontFamily="1"
            :fontSize="3"
            >ENCADRANT :
            <DoiText
              type="span"
              class="font-text uppercase"
              :textColor="1"
              :fontFamily="0"
              :fontSize="3"
              >{{ item.description.encadrant }}
            </DoiText></DoiText
          >
        </div>
        <div>
          <ul class="description-list ml-4 md:ml-8">
            <li v-for="(text, index) in item.description.content" :key="index">
              {{ text }}
            </li>
          </ul>
        </div>
      </div>
      <img class="lg:hidden ml-6" :src="item.description.img" />
    </div>
    <div v-if="item.typeCreneau == 'cours'">
      <DoiAccordionButton
        :primaryText="window.screen.width > 1024 ? 'Détails' : ''"
        :open="openIndex === index"
        @toggle="$emit('toggleOpen')"
      />
    </div>
    <div class="flex">
      <div class="w-14 mr-2" />
      <div
        v-if="
          item.tarif &&
          (item.typeCreneau == 'terrain' || item.typeCreneau == null)
        "
        class="text-right lg:hidden w-4/6"
      >
        <DoiText :textColor="0" :fontFamily="1" class="tracking-wide"
          >PRIX TERRAIN : {{ item.tarif / 100 }} &euro;</DoiText
        >
      </div>
      <div v-else class="text-right lg:hidden w-4/6">
        <DoiText :textColor="0" :fontFamily="1" class="tracking-wide"
          >GRATUIT</DoiText
        >
      </div>
      <div class="w-1/6" />
    </div>
    <div v-if="cartRemise > 0" class="flex">
      <div class="w-14 sm:w-20" />
      <DoiText
        :textColor="1"
        :fontFamily="4"
        class="tracking-wide w-5/6 list-remise mb-2 mt-4"
        >* Vous bénéficiez d'une réduction sur ce créneau de {{ cartRemise }}%
        qui sera appliquée automatiquement sur le montant de votre paiement.
      </DoiText>
    </div>
  </div>
</template>

<script>
import sports from "@/data/sports.json";
import dayjs from "dayjs";
import { cartUpdateLine } from "@/api/CartService";

export default {
  name: "CartPaymentItem",
  props: {
    item: Object,
    readOnly: {
      type: Boolean,
      default: false,
    },
    shareBill: Boolean,
    v2: String,
    openIndex: Number,
    index: Number,
  },
  computed: {
    cartRemise() {
      const remise = this.$store.getters["UserModule/get_user_remise"];
      if (remise !== undefined) return remise * 100;
      return 0;
    },
    sportIcon() {
      let icon = "";
      const sp = sports.find(
        (sport) => sport.label === this.item.sport.toLowerCase().trim()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    date() {
      const day = dayjs(this.item.date_heure_debut).format("DD");
      const year = dayjs(this.item.date_heure_debut).format("YYYY");
      let month = dayjs(this.item.date_heure_debut).format("MMMM");
      const heureDebut = dayjs(this.item.date_heure_debut).format("HH:mm");
      const heureFin = dayjs(this.item.date_heure_fin).format("HH:mm");
      return `LE ${day} ${month} ${year} DE ${heureDebut} À ${heureFin}`;
    },
  },
  methods: {
    deleteElement(id) {
      this.$emit("deleteCartElement", id);
    },
    formattedSlotDuration(item) {
      const duree = item.dureeCreneau;
      const hour = Math.floor(duree / 60);
      let min = duree % 60;
      if (hour > 0 && min <= 9 && min !== 0)
        min = min.toString().padStart(2, "0");
      let r;

      if (hour > 0 && min > 0) r = `${hour}h${min}min`;
      else if (hour > 0 && min === 0) r = `${hour}h`;
      else if (hour === 0 && min > 0) r = `${min}min`;
      return r;
    },
    modifyCart(itemId) {
      const data = {
        idLigne: itemId,
        quantite: parseInt(this.item.quantite),
      };

      let result = {};
      setTimeout(async () => {
        result = await cartUpdateLine(data);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.list-remise li::marker {
  font-size: 8px;
}

.list-remise p {
  font-size: 10px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 200, "GRAD" -25, "opsz" 20;
}

.box-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.border-item {
  border-bottom: dotted 1px #dedede;
}

.bg-cart-item {
  background-color: #e9e9ed;
  margin-top: 5px;
  padding-left: 10px;
  border-radius: 5px;
  width: 18%;
}

.description-list {
  list-style: disc;
  font-size: 1rem;
  font-family: $doi--text-font-family0;
}

@media (max-width: 400px) {
  .font-text,
  .description-list {
    font-size: 0.6rem;
  }

  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 200, "GRAD" -25, "opsz" 15;
    font-size: 16px;
  }
}

@media (min-width: 400px) and (max-width: $doi-breakpoint-mobile) {
  .font-text,
  .description-list {
    font-size: 0.8rem;
  }
}

@media (min-width: $doi-breakpoint-tablet) {
  .bg-cart-item {
    background-color: #e9e9ed;
    margin-left: 20px;
    padding-left: 10px;
    border-radius: 5px;
    width: 8%;
  }

  .list-remise p {
    font-size: 12px;
  }
}
</style>
