<template>
  <div class="flex flex-col flex-1 justify-center">
    <PaymentAbstract
      :readOnly="false"
      :isCartLoading="isCartLoading"
      :isOrganizer="true"
    />
  </div>
</template>

<script>
import PaymentAbstract from "@/views/reglements/PaymentAbstract";
import { mapGetters } from "vuex";

export default {
  name: "Payment",
  components: {
    PaymentAbstract,
  },
  computed: {
    ...mapGetters("UserModule", ["get_user"]),
    ...mapGetters("CartModule", ["get_cart"]),
    isCartLoading() {
      return this.$store.getters["CartModule/isCartLoading"];
    },
    loading() {
      return this.$store.getters["CartModule/isCartLoading"];
    },
  },
};
</script>
