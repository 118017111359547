<template>
  <div>
    <div v-for="(payment, index) in paymentList" :key="payment.reservationId">
      <PaymentHistoryListItem
        :isOdd="index % 2 ? true : false"
        :payment="payment"
        class="mt-4 mb-14"
      />
    </div>
  </div>
</template>

<script>
import PaymentHistoryListItem from "@/views/reglements/PaymentHistoryListItem";

export default {
  name: "PaymentHistoryList",
  components: { PaymentHistoryListItem },
  props: {
    paymentList: Array,
  },
};
</script>
