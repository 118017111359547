<template>
  <div class="w-full flex-1 justify-center">
    <div class="flex justify-start md:justify-center w-full mb-6">
      <DoiHeader
        :level="3"
        :fontFamily="1"
        class="text-2xl md:text-4xl tracking-wide"
        >MES PAIEMENTS</DoiHeader
      >
    </div>

    <PaymentMeans v-if="aliasIsSet" />
    <div v-if="!aliasIsSet" class="flex items-center justify-center my-6">
      <p>{{ message }}</p>
    </div>

    <PaymentToBeSettled />
    <PaymentToCome />
    <PaymentHistory />
  </div>
</template>

<script>
import PaymentMeans from "@/views/reglements/PaymentMeans";
import PaymentHistory from "@/views/reglements/PaymentHistory";
import PaymentToBeSettled from "@/views/reglements/PaymentToBeSettled";
import PaymentToCome from "@/views/reglements/PaymentToCome";

import { getPaymentAliasV2 } from "@/api/PaymentService";

import { mapGetters } from "vuex";

export default {
  name: "Reglements",
  data() {
    return {
      paymentList: [],
      reservations: [],
      message:
        "Vous aurez la possibilité de mettre à jour vos coordonnées bancaires lors de votre prochaine réservation.",
      aliasIsSet: false,
    };
  },
  components: {
    PaymentMeans,
    PaymentHistory,
    PaymentToBeSettled,
    PaymentToCome,
  },
  mounted() {
    setTimeout(async () => {
      await getPaymentAliasV2().then((r) => {
        if (r.bSucces === true) this.aliasIsSet = true;
      });
    }, 1000);
  },
  computed: {
    ...mapGetters("PaymentModule", ["alias"]),
  },
};
</script>
