<template>
  <div
    class="mb-12 lg:mb-16 border-b border-black relative"
    :id="booking.id_reservation"
  >
    <DoiCard
      :borderLeftColor="typeResa"
      class="p-4 cursor-pointer"
      @click="toggleBookingResponse"
    >
      <template v-slot:center>
        <div class="flex justify-start flex-col">
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="5"
            :fontWeight="6"
            class="uppercase mb-2 mr-3 text-left intitule"
            >{{ booking.intitule }}</DoiText
          >
          <!-- <div class="flex flex-col items-start md:flex-row">
            <DoiText :fontFamily="0" :textColor="1" :fontSize="3">{{
              booking.zone
            }}</DoiText>
            <span class="flex justify-start items-center">
              <span class="md:ml-3 mr-3 w-3 h-3 bg-fa-red"></span>
              <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
                booking.organisme
              }}</DoiText>
            </span>
          </div> -->
          <div class="flex flex-col items-start md:flex-row">
            <DoiText :fontFamily="0" :textColor="1" :fontSize="3">{{
              booking.libelle
            }}</DoiText>
            <span class="flex justify-start items-center">
              <span class="md:ml-3 mr-3 w-3 h-3 bg-fa-red"></span>
              <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
                booking.libelle_fit_arena
              }}</DoiText>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div
          class="flex flex-col items-end"
          v-if="booking.type_resa === 'grand_public'"
        >
          <span class="flex justify-between items-center">
            <DoiText
              :fontFamily="0"
              :textColor="7"
              :fontSize="3"
              class="hidden md:block mr-1"
              >Accepté :</DoiText
            >
            <DoiText
              :fontFamily="0"
              :fontSize="3"
              class="w-16 p-1 green mb-1 flex justify-center items-center"
              >{{ nb("ok") }} / {{ nbPlaceTotal }}</DoiText
            >
          </span>
          <span class="flex justify-between items-center">
            <DoiText
              :fontFamily="0"
              :textColor="0"
              :fontSize="3"
              class="hidden md:block mr-1"
              >Refusé :</DoiText
            >
            <DoiText
              :fontFamily="0"
              :fontSize="3"
              class="w-16 p-1 bg-fa-red mb-1 flex justify-center items-center"
              >{{ nb("cancel") }}</DoiText
            >
          </span>
          <span class="flex justify-between items-center">
            <DoiText
              :fontFamily="0"
              :textColor="3"
              :fontSize="3"
              class="hidden md:block mr-1"
              >Peut-être :</DoiText
            >
            <DoiText
              :fontFamily="0"
              :fontSize="3"
              class="w-16 p-1 gray mb-1 flex justify-center items-center"
              >{{ nb("pending") }}</DoiText
            >
          </span>
        </div>
        <div
          class="flex flex-col items-end justify-end"
          v-if="
            booking.type_resa === 'animateur' ||
            booking.type_resa === 'gestionnaire_organisme'
          "
        >
          <span class="flex justify-between items-center">
            <DoiText
              :fontFamily="0"
              :textColor="7"
              :fontSize="3"
              class="hidden md:block mr-1"
              >Accepté :</DoiText
            >
            <DoiText
              :fontFamily="0"
              :fontSize="3"
              class="w-16 p-1 green mb-1 flex justify-center items-center"
              >{{ nb("ok") }}
            </DoiText>
          </span>
          <span class="flex justify-between items-center">
            <DoiText
              :fontFamily="0"
              :textColor="0"
              :fontSize="3"
              class="hidden md:block mr-1"
              >Refusé :</DoiText
            >
            <DoiText
              :fontFamily="0"
              :fontSize="3"
              class="w-16 p-1 bg-fa-red mb-1 flex justify-center items-center"
              >{{ nb("cancel") }}</DoiText
            >
          </span>
        </div>
      </template>
    </DoiCard>
    <div v-if="bookingOpen">
      <BookingItemDetail
        :bookingDetail="booking"
        :zone="zone"
        :organisme="organisme"
        :isSharing="isSharing"
      />
    </div>
    <div
      v-if="!isCancel"
      @click="toggleBookingResponse"
      class="bg-black absolute right-0 px-5 py-2 flex items-center justify-between w-full lg:w-4/12 cursor-pointer"
    >
      <DoiText :fontFamily="4" class="uppercase tracking-wider">{{
        bookingOpen === false
          ? "Ouvrir la réservation"
          : "Fermer la réservation"
      }}</DoiText>
      <span class="text-white material-icons">{{
        bookingOpen === false ? "keyboard_arrow_down" : "keyboard_arrow_up"
      }}</span>
    </div>
    <div
      v-else
      class="bg-black absolute right-0 px-5 py-2 flex items-center justify-between w-full lg:w-4/12"
    >
      <DoiText :fontFamily="4" class="py-1 uppercase tracking-wider"
        >Réservation annulée</DoiText
      >
    </div>

    <Modal
      title="MODIFIER LA RÉPONSE"
      :open="modalResponse"
      size="xs"
      :keepratio="true"
      @close-modal="modalResponse = false"
    >
      <template slot="modal_content">
        <div class="flex flex-col">
          <div class="sm:flex sm:flex-col md:flex md:flex-row">
            <div class="customRadio valide mb-4 lg:mb-0 lg:mx-2 flex-1 xs:mb-4">
              <input
                type="radio"
                id="reponse_valide"
                name="reponse"
                value="valide"
                @change="setReponse($event.target.value)"
                :checked="this.currentUser.sStatut === 'valide'"
              />
              <label for="reponse_valide" class="flex justify-center"
                >Je valide</label
              >
            </div>
            <div
              class="customRadio refuse mb-4 lg:mb-0 lg:mx-2 flex-1 text-center xs:mb-4"
            >
              <input
                type="radio"
                id="reponse_refuse"
                name="reponse"
                value="refuse"
                @change="setReponse($event.target.value)"
                :checked="this.currentUser.sStatut === 'refuse'"
              />
              <label for="reponse_refuse" class="flex justify-center"
                >Je refuse</label
              >
            </div>
            <div
              class="customRadio peut_etre mb-4 lg:mb-0 lg:mx-2 flex-1 text-center xs:mb-4"
            >
              <input
                type="radio"
                id="reponse_peut-etre"
                name="reponse"
                value="peut-etre"
                @change="setReponse($event.target.value)"
                :checked="this.currentUser.sStatut === 'peut-etre'"
              />
              <label for="reponse_peut-etre" class="flex justify-center"
                >Peut être</label
              >
            </div>
          </div>
          <div>
            <div class="flex p-2 mt-4">
              <button
                color="red"
                class="duration-100 text-xs focus:outline-none text-white uppercase w-full py-2 rounded-md flex justify-center items-center bg-fa-red hover:bg-red-700"
                @click="_changeResponse()"
              >
                ENVOYER MA RÉPONSE
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <Modal
      title="INVITER DES JOUEURS"
      :open="openInvitGamer"
      size="sm"
      :keepratio="true"
      @close-modal="openInvitGamer = false"
    >
      <template slot="modal_content">
        <BookingGamer :booking="booking" :isOrganizer="isOrganizer" />
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import { setChangeResponse } from "@/api/BookingService";
import BookingGamer from "./BookingGamer";
import BookingItemDetail from "./BookingItemDetail";

import { getUserReservations } from "@/api/UserService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "BookingItem",
  data() {
    return {
      bookingOpen: false,
      contactId: null,
      userId: null,
      isOrganizer: false,
      modalResponse: false,
      currentUser: {},
      sReponse: "",
      openBookingEdit: false,
      openInvitGamer: false,
      sIdReservation: "",
      openDemo: false,
      participants: [],
      zone: "",
      organisme: "",
      isSharing: false,
    };
  },
  props: {
    booking: {
      type: Object,
      default: () => {},
    },
    firstItem: {
      type: Boolean,
      default: false,
    },
    firstItemReservation: {
      type: Boolean,
      default: false,
    },
    showReservation: {
      required: false,
    },
  },
  components: {
    Modal,
    BookingGamer,
    BookingItemDetail,
  },
  async mounted() {
    this.bookingOpen =
      this.showReservation === "auto"
        ? this.firstItem
        : this.firstItemReservation;
    this.contactId = this.$store.getters["UserModule/get_contact_id"];
    this.userId = this.$store.getters["UserModule/get_user_id"];
    this.participants = Object.values(this.booking.participants);
    if (localStorage.getItem("idReservation") === this.booking.id_reservation) {
      this.bookingOpen = true;
      localStorage.removeItem("idReservation");
    }
    const reservationsUser = await getUserReservations(this.get_userIdV2);
    const reservation = reservationsUser.reservations.find(
      (item) => item.uuidReservation === this.booking.id_reservation
    );
    this.zone = reservation.zone;
    this.organisme = reservation.organisme;
    this.isSharing = reservation.isSharing;
  },
  computed: {
    ...mapGetters("AuthModule", ["get_userIdV2"]),
    typeResa() {
      let style = 4;
      switch (this.booking.type_resa) {
        case "grand_public":
          style = 0; // red
          break;
        case "gestionnaire_organisme":
        case "animateur":
          style = 1; // blue
          break;
      }
      return style; // grey (adhérent)
    },
    nbPlaceTotal() {
      return this.booking.place_max;
    },
    isCancel() {
      return this.booking.statut === "annulee";
    },
    organisateur() {
      return this.participants.find(
        (participant) => participant.concerne_par_resa === true
      );
    },
  },
  methods: {
    ...mapActions("ReservationListModule", ["retrieveReservations"]),
    nb(type) {
      let status;
      switch (type) {
        case "cancel":
          status = 1;
          break;
        case "ok":
          status = 2;
          break;
        case "pending":
          status = 0;
          break;
      }

      const result = this.booking.participants.reduce(
        (acc, participant) => (participant.reponse === status ? acc + 1 : acc),
        0
      );
      this.$props.booking[status] = result;
      return result || 0;
    },
    toggleBookingResponse() {
      if (!this.isCancel) this.bookingOpen = !this.bookingOpen;
    },
    setCurrentUser(contact) {
      this.currentUser = contact;
    },
    setReponse(response) {
      this.sReponse = response;
    },
    _changeResponse() {
      setChangeResponse({
        sIdReservation: this.currentUser.sIdReservation,
        nIdGrandPublic: this.currentUser.nIdInvite,
        sJetonInvitation: this.$props.booking.sJetonInvitation,
        sReponse: this.sReponse,
        sMd5ActivationOrganisateur: "",
      }).then((res) => {
        if (res.bSucces === true) {
          const message = "Le changement de réponse a bien été effectué";
          if (screen.width > 640) {
            this.$toast.success(message);
          } else {
            this.$toast.success(message, {
              position: "top",
            });
          }
        }
      });
      this.$emit("onUpdateBooking");
      this.modalResponse = false;
      this.sReponse = "";
    },
  },
  watch: {
    index: {
      immediate: true,
      handler() {
        if (this.firstItem === true && !localStorage.getItem("idReservation"))
          this.bookingOpen = true;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.green {
  background-color: $doi--color-color2;
}

.gray {
  background-color: $doi--color-color4;
}

@media (max-width: $doi-breakpoint-mobile) {
  .intitule {
    font-size: 1.3rem;
  }
}
</style>
