<template>
  <div class="w-full p-4">
    <div>
      <div
        v-for="(day, index) in slotsData"
        :key="index"
        class="border p-3 mb-4 bg-white flex flex-col"
      >
        <PlanningLightReservationItem :day="day" :setOpen="index === 0" />
      </div>
    </div>
  </div>
</template>

<script>
import PlanningLightReservationItem from "./PlanningLightReservationItem";
import dayjs from "dayjs";

export default {
  name: "PlanningLightReservation",
  data() {
    return {
      lastDate: null,
      lastIndex: -1,
      slotsData: [],
    };
  },
  props: {
    reservations: Array,
  },
  components: {
    PlanningLightReservationItem,
  },
  methods: {
    compareDate(a, b) {
      const dateA = new Date(a.start).getTime();
      const dateB = new Date(b.start).getTime();
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    },
  },
  watch: {
    reservations: {
      immediate: true,
      handler(value) {
        this.slotsData = [];
        this.lastIndex = -1;
        this.lastDate = null;
        const copyArray = [...value];

        copyArray.sort(this.compareDate).forEach((slot, index) => {
          const currentDate = dayjs(slot.start).format("YYYY-MM-DD");
          const newEntry = {
            sLibelle: dayjs(slot.start).format("dddd D MMMM"),
            aSlot: [],
          };

          if (this.lastDate !== currentDate) {
            this.slotsData.push(newEntry);
            this.lastIndex += 1;
          }

          this.slotsData[this.lastIndex].aSlot.push(slot);
          this.lastDate = currentDate;
        });
      },
    },
  },
};
</script>
