<template>
  <div class="relative">
    <Card
      :borderLeftColor="0"
      @click="open = !open"
      class="sm:p-4 flex-col lg:flex-row cursor-pointer"
    >
      <template v-slot:center>
        <div
          class="hidden sm:flex flex-col justify-start items-start py-4 pl-2"
        >
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="5"
            :fontWeight="6"
            class="uppercase"
            >{{ payment.activity }}</DoiText
          >
          <div class="flex flex-col sm:flex-row mt-2">
            <DoiText :fontFamily="0" :textColor="1" :fontSize="3">{{
              formatDate(payment.startDate)
            }}</DoiText>
            <div class="flex items-center">
              <DoiText type="span" class="sm:mx-1 mr-2 w-3 h-3 block bg-red" />
              <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
                payment.location
              }}</DoiText>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col sm:hidden justify-start items-start py-4 pl-2"
        >
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="4"
            :fontWeight="6"
            class="uppercase"
            >{{ payment.activity }}</DoiText
          >
          <div class="flex flex-col sm:flex-row mt-2">
            <DoiText :fontFamily="0" :textColor="1" :fontSize="3">{{
              formatDate(payment.startDate)
            }}</DoiText>
            <div class="flex items-center">
              <DoiText type="span" class="sm:mx-1 mr-2 w-3 h-3 block bg-red" />
              <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
                payment.location
              }}</DoiText>
            </div>
          </div>
          <DoiText
            v-if="isContentiousPayment(payment.payments[0].paiementStatus)"
            :fontFamily="1"
            :fontSize="4"
            :textColor="0"
            class="hidden sm:block font-bold"
            >CONTENTIEUX</DoiText
          >
        </div>
      </template>
      <template v-slot:right>
        <div class="flex flex-col items-end justify-between pr-2 py-4">
          <div class="flex items-center gap-8">
            <DoiText
              v-if="isContentiousPayment(payment.payments[0].paiementStatus)"
              :fontFamily="1"
              :fontSize="4"
              :textColor="0"
              class="font-bold hidden sm:block"
              >CONTENTIEUX</DoiText
            >
            <DoiText
              :fontFamily="0"
              :fontSize="3"
              class="w-16 p-1 rounded-lg green mb-1 flex justify-center items-center"
              >{{ payment.participantCount }} /
              {{ payment.maxParticipantCount }}</DoiText
            >
          </div>
          <DoiText :textColor="1" :fontSize="3" :fontFamily="1">{{
            formatPrice(payment.totalAmount)
          }}</DoiText>
        </div>
      </template>
    </Card>
    <div v-if="open" :class="open ? 'border-b border-black' : ''">
      <div class="hidden sm:flex my-4 px-4 justify-between items-center">
        <DoiText :fontFamily="1" :textColor="1" :fontSize="4"
          >{{ payment.participantCount }} /
          {{ payment.maxParticipantCount }} participants</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="4"
          >Prix du terrain : {{ formatPrice(payment.fieldPrice) }}</DoiText
        >
      </div>
      <div class="flex sm:hidden my-4 px-4 justify-between items-center">
        <DoiText :fontFamily="1" :textColor="1" :fontSize="2"
          >{{ payment.maxParticipantCount }} participants</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Prix du terrain : {{ formatPrice(payment.fieldPrice) }}</DoiText
        >
      </div>
      <div
        v-for="(item, index) in payment.payments"
        :key="`paymentItem-${index}`"
      >
        <div
          class="flex justify-between items-center px-4 py-2 payments-details"
        >
          <DoiText
            :fontFamily="0"
            :textColor="1"
            :fontSize="3"
            class="sm:w-60"
            >{{ item.playerName }}</DoiText
          >
          <div
            v-if="
              item.hasOwnProperty('discountCodes') &&
              item.discountCodes.length > 0
            "
          >
            <DoiText
              :fontFamily="0"
              :textColor="1"
              :fontSize="3"
              v-if="item.discountCodes[0].hasOwnProperty('percent')"
              >-{{ item.discountCodes[0].percent }}</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="1"
              :fontSize="3"
              v-if="item.discountCodes[0].hasOwnProperty('amount')"
              >{{ formatPrice(item.discountCodes[0].amount) }}</DoiText
            >
          </div>
          <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
            formatPrice(item.totalPaidAmount)
          }}</DoiText>
        </div>
      </div>
      <DoiText
        :fontFamily="1"
        :textColor="1"
        :fontSize="4"
        class="hidden sm:block text-right mt-4 px-4"
        >Total provisoire : {{ formatPrice(payment.totalAmount) }}</DoiText
      >
      <DoiText
        :fontFamily="1"
        :textColor="1"
        :fontSize="3"
        class="sm:hidden text-right my-4 px-4"
        >Total provisoire : {{ formatPrice(payment.totalAmount) }}</DoiText
      >
      <div class="px-4 mb-4 hidden sm:block">
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >Tentative de prélèvement le
          {{ formatDate(payment.orderDate) }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >Par carte bancaire : {{ payment.cardPan }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >Refusé : {{ payment.paymentRefusedReason }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >UUID réservation : {{ payment.uuidReservation }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >Référence du paiement : {{ payment.referencePaiement }}</DoiText
        >
      </div>
      <div class="px-4 mb-4 sm:hidden">
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Tentative de prélèvement le
          {{ formatDate(payment.orderDate) }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Par carte bancaire : {{ payment.cardPan }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Refusé : {{ payment.paymentRefusedReason }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >UUID réservation : {{ payment.uuidReservation }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Référence du paiement : {{ payment.referencePaiement }}</DoiText
        >
      </div>
      <DoiButton
        v-if="
          isContentiousPayment(payment.payments[0].paiementStatus) &&
          payment.payments[0].paiementOrderUrl === '' &&
          !orderPaymentIsAvailable
        "
        @click="submitPayment"
        class="w-full sm:w-5/12 px-4 my-4"
        :fontFamily="6"
        :bgColor="0"
        :bgHover="1"
        >GÉNÉRER UN ORDRE DE PAIEMENT</DoiButton
      >
      <DoiButton
        v-if="
          isContentiousPayment(payment.payments[0].paiementStatus) &&
          payment.payments[0].paiementOrderUrl !== ''
        "
        @click="submitPayment"
        class="w-full sm:w-3/12 px-4 my-4"
        :fontFamily="6"
        :bgColor="0"
        :bgHover="1"
        >RÉGULARISER</DoiButton
      >
    </div>
    <div
      class="border-t"
      :class="
        isContentiousPayment(payment.payments[0].paiementStatus)
          ? 'border-red-600'
          : 'border-black'
      "
    >
      <div
        @click="open = !open"
        class="absolute right-0 px-5 py-2 flex items-center justify-between cursor-pointer w-full lg:w-4/12 lg:-bottom-10 border-button"
        :class="
          isContentiousPayment(payment.payments[0].paiementStatus)
            ? 'bg-red'
            : 'bg-black'
        "
      >
        <DoiText
          :fontFamily="4"
          class="uppercase tracking-wider"
          v-if="isContentiousPayment(payment.payments[0].paiementStatus)"
          >RÉGULARISER LE PAIEMENT</DoiText
        >
        <DoiText :fontFamily="4" class="uppercase tracking-wider" v-else
          >DÉTAILS</DoiText
        >
        <DoiText class="material-icons">{{
          open === false ? "keyboard_arrow_down" : "keyboard_arrow_up"
        }}</DoiText>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/atomic/Card";

import { sendPaymentOrder } from "@/api/PaymentService";

import dayjs from "dayjs";

export default {
  name: "PaymentToBeSettledListItem",
  data() {
    return {
      open: false,
    };
  },
  components: { Card },
  props: {
    isOdd: {
      type: Boolean,
      default: false,
    },
    payment: Object,
  },
  computed: {
    canGeneratePaymentOrder() {
      // générer un ODP si je n'ai pas d'url d'ODP ou si la date de l'ODP est expirée
      return (
        this.payment.payments[0].paiementOrderUrl === "" ||
        !this.orderPaymentIsAvailable
      );
    },
    orderPaymentIsAvailable() {
      const today = dayjs(new Date()).unix();
      const orderPaymentDate = dayjs(
        this.payment.payments[0].dateExpirationOrderPayment
      ).unix();
      return today < orderPaymentDate;
    },
  },
  methods: {
    async generatePaymentOrder(id) {
      await sendPaymentOrder(id)
        .then((response) => {
          window.open(response.paymentOrderUrl, "_blank");
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    isContentiousPayment(status) {
      return status === "contentious";
    },
    async submitPayment() {
      const payment = this.payment.payments[0];

      // vérifier si paiementOrderUrl est "" ou non
      if (payment.paiementOrderUrl === "") {
        // pas d'OP existant
        // générer un OP
        await this.generatePaymentOrder(payment.paiementId);
        return;
      } else {
        // vérifier la date d'expiration de l'OP
        if (this.orderPaymentIsAvailable) {
          // si le lien n'est pas expiré
          window.open(payment.paiementOrderUrl, "_blank");
        } else {
          // générer un OP
          await this.generatePaymentOrder(payment.paiementId);
          return;
        }
      }
    },
    formatDate(startDate) {
      return dayjs(startDate).format("DD-MM-YYYY" + " à " + "HH[h]mm");
    },
    formatPrice(price) {
      return Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(price / 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.green {
  background-color: $doi--color-color2;
}

.bg-red {
  background-color: $doi--color-color0;
}

.payments-details {
  border-bottom: 1px solid black;
}
</style>
