<template>
  <div class="flex flex-col">
    <div class="flex flex-col lg:flex-row pb-3 border-b-2 mb-5">
      <DoiText
        :textColor="1"
        :fontSize="4"
        :fontFamily="1"
        class="uppercase mr-2"
        >{{ booking.intitule }}</DoiText
      >
      <DoiText :textColor="1" :fontSize="4" :fontFamily="0">{{
        booking.libelle
      }}</DoiText>
      <div class="flex">
        <span class="w-3 h-3 bg-fa-red block lg:mx-2 mr-2 my-3"></span>
        <DoiText :textColor="1" :fontSize="4" :fontFamily="1">{{
          booking.libelle_fit_arena
        }}</DoiText>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-col w-full lg:w-5/12 lg:pr-8">
        <div class="flex">
          <div
            class="flex justify-between items-center text-center w-full pb-2"
          >
            <div class="text-left">
              <DoiText :textColor="0" :fontSize="4" :fontFamily="1"
                >SCORE FINAL</DoiText
              >
            </div>
            <div class="flex justify-center">
              <DoiText
                :textColor="0"
                :fontSize="4"
                :fontFamily="1"
                class="score"
                >{{ score.replace(":", " - ") }}</DoiText
              >
              <div class="flex items-center sm:mr-2">
                <div
                  v-if="match.videos.length > 0"
                  @click="getFullVideo()"
                  class="cursor-pointer flex items-center justify-center w-8 h-8 bg-fa-red"
                >
                  <img src="@/assets/icons/common/play.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="tempsForts.length === 0" class="flex flex-col">
          <div class="flex mt-3 justify-around text-center">
            Aucun évènement dans cette partie
          </div>
        </div>
        <div class="flex flex-col border-b pb-4">
          <div v-for="(item, index) in tempsForts" :key="index">
            <div class="flex mt-3 justify-around items-center text-center">
              <div class="text-left time">
                <DoiText
                  v-if="item.instant < 60"
                  :textColor="3"
                  :fontFamily="1"
                  :fontSize="3"
                  >{{ item.instant }}''</DoiText
                >
                <DoiText v-else :textColor="3" :fontFamily="1" :fontSize="3"
                  >{{ Math.trunc(item.instant / 60) }}'</DoiText
                >
              </div>

              <div
                v-if="
                  booking.intitule == 'Basket 5x5' ||
                  booking.intitule == 'Basket 3x3'
                "
                class="flex-1 text-left capitalize truncate"
              >
                <DoiText
                  v-if="item.categorie == 'but'"
                  :textColor="3"
                  :fontFamily="4"
                  :fontSize="3"
                  class="temps-forts"
                  >Panier {{ item.pseudo ? `de ${item.pseudo}` : `` }}</DoiText
                >
                <DoiText
                  v-else
                  :textColor="3"
                  :fontFamily="4"
                  :fontSize="3"
                  class="temps-forts"
                  >{{ item.categorie }}
                  {{ item.pseudo ? `de ${item.pseudo}` : `` }}</DoiText
                >
              </div>
              <div v-else class="flex-1 text-left capitalize truncate">
                <DoiText
                  :textColor="3"
                  :fontFamily="4"
                  :fontSize="3"
                  class="temps-forts"
                  >{{ item.categorie }}
                  {{ item.pseudo ? `de ${item.pseudo}` : `` }}</DoiText
                >
              </div>

              <div class="flex score justify-center">
                <DoiText :textColor="3" :fontFamily="1" :fontSize="4">{{
                  item.score.replace(":", " - ")
                }}</DoiText>
              </div>

              <div
                class="flex justify-center items-center"
                v-if="item.videos && item.videos.length > 0"
              >
                <div class="flex items-center sm:mr-2">
                  <div
                    @click="getVideoTempsFort(index)"
                    class="cursor-pointer flex items-center justify-center w-8 h-8 bg-gray"
                  >
                    <img src="@/assets/icons/common/play.svg" />
                  </div>
                </div>
                <div
                  class="flex items-center mr-2"
                  v-if="item.videos && item.videos.length == 0"
                >
                  <div
                    class="cursor-pointer flex items-center justify-center w-8 h-8 bg-red"
                  >
                    <img src="@/assets/icons/common/play.svg" />
                  </div>
                </div>
                <!-- <div class="hidden sm:flex cursor-pointer items-center share justify-center w-8 h-8 mr-2 bg-red">
                  <ShareNetwork
                    network="facebook"
                    :url="`${item.videos[0].url}`"
                    :quote="`Replay - ${booking.intitule} ${booking.libelle} - ${item.categorie} de ${item.pseudo}`"
                    title=""
                    hashtags="FitArena"
                  ><img class="w-6" src="@/assets/img/share/facebook.png" alt="Facebook Fit Arena" />
                  </ShareNetwork>
                </div>
                <div class="hidden sm:flex cursor-pointer items-center share justify-center w-8 h-8 bg-red">
                  <ShareNetwork
                    network="twitter"
                    :url="`${item.videos[0].url}`"
                    :title="`Replay - ${booking.intitule} ${booking.libelle} - ${item.categorie} de ${item.pseudo}`"
                    hashtags="FitArena"
                  ><img class="w-6" src="@/assets/img/share/twitter.png" alt="Twitter Fit Arena" />
                  </ShareNetwork>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-6/12 mt-4 lg:mt-0">
        <div
          v-if="this.match.videos.length > 0"
          class="w-full flex items-start justify-center"
        >
          <video :poster="thumbnail" :key="video" controls autoplay muted>
            <source :src="video" type="video/mp4" codecs="avc1.4d002a" />
          </video>
          <div v-if="selectedTempsFort" class="flex flex-col">
            <div
              class="hidden sm:flex cursor-pointer items-center share justify-center w-12 h-12 ml-2 mb-2 bg-red"
            >
              <ShareNetwork
                network="facebook"
                :url="`${video}`"
                :quote="`Replay - ${booking.intitule} ${booking.libelle} - ${selectedTempsFort.categorie} de ${selectedTempsFort.pseudo}`"
                title=""
                hashtags="FitArena"
                ><img
                  class="w-8"
                  src="@/assets/img/share/facebook.png"
                  alt="Facebook Fit Arena"
                />
              </ShareNetwork>
            </div>
            <div
              class="hidden sm:flex cursor-pointer items-center share justify-center w-12 h-12 ml-2 mt-2 bg-red"
            >
              <ShareNetwork
                network="twitter"
                :url="`${video}`"
                :title="`Replay - ${booking.intitule} ${booking.libelle} - ${selectedTempsFort.categorie} de ${selectedTempsFort.pseudo}`"
                hashtags="FitArena"
                ><img
                  class="w-8"
                  src="@/assets/img/share/twitter.png"
                  alt="Twitter Fit Arena"
                />
              </ShareNetwork>
            </div>
          </div>
        </div>
        <div v-else>
          <DoiText :textColor="1" :fontSize="3" class="mx-auto text-center"
            >Cette vidéo n'est pas encore disponible.</DoiText
          >
        </div>
        <DoiText
          :fontFamily="0"
          :fontSize="3"
          :textColor="3"
          class="sm:hidden mb-2 mt-4"
          >Choisissez une caméra</DoiText
        >
        <div class="flex justify-center sm:pt-4 lg:flex-row items-center mb-2">
          <DoiText
            :fontFamily="0"
            :fontSize="3"
            :textColor="3"
            class="hidden sm:flex mb-2 mt-4 lg:mb-0 mr-8"
            >Choisissez une caméra</DoiText
          >
          <div class="btn w-auto lg:mr-4 lg:mb-0">
            <button
              class="button red icon mt-2"
              :class="nCamera === 0 ? 'red' : 'disabled'"
              @click="nCamera = 0"
            >
              <img src="@/assets/icons/common/video-icon.svg" />
              <DoiText :fontSize="3" :fontFamily="1">CAMÉRA 1</DoiText>
            </button>
          </div>
          <div class="btn w-auto">
            <button
              class="button red icon ml-4 lg:ml-0 mt-2"
              :class="nCamera === 1 ? 'red' : 'disabled'"
              @click="nCamera = 1"
            >
              <img src="@/assets/icons/common/video-icon.svg" />
              <DoiText :fontSize="3" :fontFamily="1">CAMÉRA 2</DoiText>
            </button>
          </div>
        </div>
        <div
          class="sm:hidden cursor-pointer mx-auto flex items-center justify-center w-10 h-10 bg-red share"
          @click="share()"
        >
          <img src="@/assets/icons/common/share.svg" width="20" height="20" />
        </div>
      </div>
    </div>
    <div class="flex flex-1 mt-2 justify-center">
      <ScoreDetailTeams
        v-if="match.id_match"
        :idPartie="match.id_match"
        :booking="booking"
      />
    </div>
  </div>
</template>

<script>
import ScoreDetailTeams from "@/views/score/ScoreDetailTeams";
import Vue from "vue";

export default {
  name: "ScoreVideo",
  data() {
    return {
      list: [],
      nCamera: 0,
      video: "",
      sVideoUrl: '<source src="" type="video/mp4">',
      id_temps_fort: 0,
      thumbnail: "",
      scoreImg: "",
      infos: "",
      expanded: false,
      shareIndex: 0,
      selectedTempsFort: this.match.temps_forts[0],
      shareData: {
        title: "Partie Fit Arena",
        text: "Venez voir ma partie Fit Arena",
        errorMessage: "Navigateur non compatible",
      },
    };
  },
  props: {
    match: {},
    booking: {},
    score: {},
  },
  components: {
    ScoreDetailTeams,
  },
  computed: {
    tempsForts() {
      let temps_forts = this.match.temps_forts;
      if (this.booking.type_feuille_match !== "set") return temps_forts;
      else {
        return temps_forts.filter(
          (temps_fort) =>
            temps_fort.categorie === "set" || temps_fort.categorie === "action"
        );
      }
    },
    tempsFortsActionSet() {
      let temps_fort_format = [];
      let temps_forts_action = [];
      this.tempsForts.forEach((tf) => {
        if (tf.categorie === "set") {
          tf.action = temps_forts_action;
          temps_fort_format.push(tf);
          temps_forts_action = [];
        }
        if (tf.categorie === "action") temps_forts_action.push(tf);
      });
      return temps_fort_format;
    },
    scoreGlobalSet() {
      let score_global_set = {
        equipe_1: 0,
        equipe_2: 0,
      };
      let sets = this.tempsForts.filter((tf) => tf.categorie === "set");
      sets.forEach((score) => {
        if (score.scoreEquipe1 > score.scoreEquipe2)
          score_global_set.equipe_1 += 1;
        else if (score.scoreEquipe1 < score.scoreEquipe2)
          score_global_set.equipe_2 += 1;
      });
      return score_global_set;
    },
  },
  methods: {
    getVideoTempsFort(index) {
      this.selectedTempsFort = this.tempsForts[index];
      this.video = this.match.temps_forts[index].videos[this.nCamera].url;
      this.shareIndex = index;
    },
    getFullVideo() {
      if (this.match.videos.length > 0) {
        this.video = this.match.videos[this.nCamera].url;
      }
    },
    share() {
      if (!window.navigator.share) {
        Vue.$toast.error(this.shareData.errorMessage, { position: "top" });
      } else {
        const data = {
          title: this.shareData.title,
          text: this.shareData.text,
          url: `${this.tempsForts[this.shareIndex].videos[0].url}`,
        };
        window.navigator.share(data);
      }
    },
    shareAction(index, i) {
      if (!window.navigator.share) {
        Vue.$toast.error(this.errorMessage, { position: "top" });
      } else {
        const data = {
          title: this.shareData.title,
          text: this.shareData.text,
          url: `${this.tempsFortsActionSet[index].action[i].videos[0].url}`,
        };
        window.navigator.share(data);
      }
    },
    getPosition(id_tf) {
      const sets = this.tempsForts.filter((tf) => tf.categorie === "set");
      return sets.findIndex((tf) => tf.id === id_tf);
    },
    setVideo(action, thumbnail) {
      this.video = action;
      this.thumbnail = thumbnail;
    },
    setMiniature(action) {
      return window.btoa(action);
    },
    setShareVideo(video) {
      return window.btoa(video);
    },
  },
  watch: {
    booking: {
      immediate: true,
      async handler() {
        if (this.match.videos.length > 0) {
          this.thumbnail = this.match.videos[0].urlMiniature;
        } else if (
          this.booking.id_sport === 2 ||
          this.booking.id_sport === 10
        ) {
          this.thumbnail =
            await require("@/assets/img/mock/video/thumbnail_video_basket.png");
        } else if (this.booking.id_sport === 4) {
          this.thumbnail =
            await require("@/assets/img/mock/video/thumbnail_video_bad.png");
        } else {
          this.thumbnail =
            await require("@/assets/img/mock/video/thumbnail_video.png");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-gray {
  background-color: $doi--color-color4;
}

.bg-red {
  background-color: $doi--color-color0;
}

video {
  width: 100%;
}

.btn {
  position: relative;
  min-width: 130px;
  min-height: 40px;
}

.time {
  min-width: 40px;
}

.temps-forts {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
}

.score {
  min-width: 90px;
}
</style>
