<template>
  <div
    v-if="getNbReservationTotal > 0 && nbTypeReservation > 1"
    class="flex items-center"
  >
    <DoiText :textColor="1" :fontFamily="6" class="mr-4">Filtres</DoiText>
    <div class="flex flex-wrap">
      <DoiFilter
        v-if="getNbReservationGrandPublic > 0"
        :active="filterActive('this.grandPublic')"
        label="Réservation"
        :color="0"
        @click="toggleTypeReservation(grandPublic)"
        class="mb-2 lg:mb-0"
      />
      <DoiFilter
        v-if="getNbReservationAdherent > 0"
        :active="filterActive('this.adherent')"
        label="Adhésion"
        :color="1"
        @click="toggleTypeReservation(adherent)"
        class="mb-2 lg:mb-0"
      />
      <DoiFilter
        v-if="getNbReservationAnimateur > 0"
        :active="filterActive('this.animateur')"
        label="Animateur"
        :color="2"
        @click="toggleTypeReservation(animateur)"
        class="mb-2 lg:mb-0"
      />
      <DoiFilter
        v-if="getNbReservationGestionnaireOrganisme > 0"
        :active="filterActive('this.gestOrg')"
        label="Gestionnaire d'organisme"
        :color="2"
        @click="toggleTypeReservation(gestOrg)"
        class="mb-2 lg:mb-0"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingSearch",
  data() {
    return {
      grandPublic: "grand_public",
      adherent: "adherent",
      animateur: "animateur",
      gestOrg: "gestionnaire_organisme",
    };
  },
  props: {
    filterList: Array,
  },
  computed: {
    nbTypeReservation() {
      let nbTypeReservation = 0;
      if (
        this.getNbReservationAdherent > 0 ||
        this.getNbReservationAnimateur > 0 ||
        this.getNbReservationGestionnaireOrganisme > 0 ||
        this.getNbReservationGrandPublic > 0
      ) {
        nbTypeReservation++;
      }
      return nbTypeReservation;
    },
    getNbReservationAdherent() {
      return this.$store.getters["UserModule/get_nb_reservation_adherent"];
    },
    getNbReservationAnimateur() {
      return this.$store.getters["UserModule/get_nb_reservation_animateur"];
    },
    getNbReservationGestionnaireOrganisme() {
      return this.$store.getters[
        "UserModule/get_nb_reservation_gestionnaire_organisme"
      ];
    },
    getNbReservationGrandPublic() {
      return this.$store.getters["UserModule/get_nb_reservation_grand_public"];
    },
    getNbReservationTotal() {
      return (
        this.getNbReservationGrandPublic +
        this.getNbReservationAdherent +
        this.getNbReservationAnimateur +
        this.getNbReservationGestionnaireOrganisme
      );
    },
    getContactId() {
      return this.$store.getters["UserModule/get_contact_id"];
    },
  },
  methods: {
    filterActive(type) {
      return this.isFilterActive(type);
    },
    isFilterActive(filterName) {
      return this.filterList.indexOf(filterName) === -1;
    },
    toggleTypeReservation(typeReservation) {
      const index = this.filterList.indexOf(typeReservation);
      const filterList = [...this.filterList];
      if (index !== -1) {
        filterList.splice(index, 1);
      } else {
        filterList.push(typeReservation);
      }
      this.$emit("updateFilter", filterList);
    },
  },
};
</script>
