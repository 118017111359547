<template>
  <div>
    <div class="flex flex-col lg:flex-row justify-between items-center mb-4">
      <div class="flex justify-start md:justify-center w-full">
        <DoiHeader
          :level="3"
          :fontFamily="1"
          class="text-2xl md:text-4xl tracking-wide"
          >MES RÉSERVATIONS</DoiHeader
        >
      </div>
    </div>
    <BookingSearch
      :filterList="filterTypeResa"
      @updateFilter="updateFilter"
      class="mb-4"
    />
    <BookingList
      :isLoading="!isFetched"
      :filterTypeResa="filterTypeResa"
      :bookingList="reservationList"
      :showReservation="showReservation"
      :key="index"
    />
  </div>
</template>

<script>
import BookingSearch from "@/views/booking/BookingSearch";
import BookingList from "@/views/booking/BookingList";

import { mapActions } from "vuex";

export default {
  name: "Booking",
  data() {
    return {
      filterTypeResa: [],
      index: 0,
      bookingOpen: 0,
    };
  },
  components: { BookingSearch, BookingList },
  props: {
    showReservation: {
      required: false,
    },
  },
  created() {
    this.removeInvitation();
  },
  async mounted() {
    await this.getBooking();
  },
  computed: {
    reservationList() {
      return this.$store.getters["ReservationListModule/reservationList"];
    },
    isFetched() {
      return this.$store.getters["ReservationListModule/isFetched"];
    },
  },
  methods: {
    ...mapActions("InvitationModule", ["removeInvitation"]),
    ...mapActions("ReservationListModule", ["retrieveReservations"]),
    updateFilter(filterList) {
      this.filterTypeResa = filterList;
      this.filterTypeResa.splice(this.filterTypeResa.length);
    },
    async getBooking() {
      await this.retrieveReservations();
    },
  },
};
</script>

<style scoped></style>
