<template>
  <div>
    <div
      @click="open = !open"
      class="flex justify-between w-full p-2 cursor-pointer"
    >
      <DoiText
        :textColor="3"
        :fontSize="3"
        :fontFamily="5"
        class="capitalize"
        >{{ day.sLibelle }}</DoiText
      >
      <span v-if="open === false" class="material-icons text-fa-dark-gray"
        >keyboard_arrow_down</span
      >
      <span v-else class="material-icons text-fa-dark-gray"
        >keyboard_arrow_up</span
      >
    </div>
    <div class="w-full border-t pt-4 mt-4" v-show="open">
      <div v-if="day.aSlot.length > 0" class="flex flex-wrap w-full">
        <div
          v-for="(slot, slotIndex) in day.aSlot"
          :key="'slot_' + slotIndex"
          class="p-1 w-1/2 text-center"
        >
          <div
            :class="
              dataSelected.some(
                (data) => parseInt(data) === parseInt(slot.nIdCreneau)
              )
                ? 'bg-fa-dark-gray'
                : 'bg-fa-red'
            "
            class="h-22 text-center p-1 flex flex-col justify-between"
          >
            <DoiText :fontSize="3">{{ slot.title }}</DoiText>
            <DoiText :fontSize="1" :fontFamily="0"
              >{{ getStart(slotIndex) }} - {{ getEnd(slotIndex) }}</DoiText
            >
            <DoiText :fontFamily="1" class="pt-1"
              >{{ getParticipantResponse(slotIndex) }} /
              {{ slot.place_max }}</DoiText
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanningLightReservationItem",
  data() {
    return {
      open: false,
      dataSelected: [],
      dayData: null,
    };
  },
  props: {
    day: {
      type: Object,
      default: {},
    },
    setOpen: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.open = this.setOpen;
  },
  computed: {
    getCart() {
      return this.$store.getters["CartModule/get_cart"];
    },
    getIdSport() {
      return this.$store.getters["FitArenaModule/get_id_sport"];
    },
    getDay() {
      return this.$props.day;
    },
  },
  methods: {
    getStart(i) {
      const hours = this.day.aSlot[i].start.split(" ")[1].split(":")[0];
      const minutes = this.day.aSlot[i].start.split(" ")[1].split(":")[1];
      return `${hours}:${minutes}`;
    },
    getEnd(i) {
      const hours = this.day.aSlot[i].end.split(" ")[1].split(":")[0];
      const minutes = this.day.aSlot[i].end.split(" ")[1].split(":")[1];
      return `${hours}:${minutes}`;
    },
    getParticipantResponse(index) {
      let counter = 0;
      for (let i = 0; i < this.day.aSlot[index].participants.length; i++) {
        if (this.day.aSlot[index].participants[i].reponse == "2") counter++;
      }
      return counter;
    },
  },
  watch: {
    getDay(val) {
      this.dayData = val;
    },
  },
};
</script>
