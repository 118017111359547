<template>
  <div class="w-full">
    <div v-if="contenu.length > 0 && !cartList.v2">
      <div v-for="data in contenu" :key="data.id">
        <CartPaymentItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :shareBill="shareBill"
        />
      </div>
      <div class="flex">
        <div class="w-14 sm:w-20 sm:h-20"></div>
        <div class="flex flex-col items-end justify-end lg:mt-10 w-4/6 mb-6">
          <template v-if="shareBill && totalPrice">
            <DoiText
              :textColor="1"
              :fontSize="3"
              :fontFamily="1"
              class="pb-2 tracking-wide md:text-base lg:text-xl"
              >TOTAL À PARTAGER : {{ (totalPrice / 100).toFixed(2) }} €
            </DoiText>
            <div class="w-full md:w-3/6 border-t border-grey"></div>
            <DoiText
              :fontFamily="4"
              :fontSize="0"
              :textColor="0"
              class="flex md:block text-right w-5/6 lg:text-xs"
              >La somme de chaque réservation sera répartie entre les
              participants.
            </DoiText>
          </template>
          <DoiText
            v-else-if="totalPrice"
            :textColor="1"
            :fontSize="3"
            :fontFamily="1"
            class="pb-2 tracking-wide md:text-base lg:text-xl"
            >TOTAL : {{ (totalPrice / 100).toFixed(2) }} €
          </DoiText>
        </div>
        <div class="w-1/6"></div>
      </div>
    </div>
    <div
      v-if="contenuTerrain.length > 0"
      :class="
        contenuPlace.length > 0 || contenuCours.length > 0
          ? 'border-b border-grey border-dotted mb-4 pb-4'
          : ''
      "
    >
      <div v-for="data in contenuTerrain" :key="data.id">
        <CartPaymentItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :shareBill="shareBill"
          :v2="v2"
        />
      </div>
      <div class="flex">
        <div class="w-14 sm:w-20 sm:h-20"></div>
        <div class="flex flex-col items-end justify-end lg:mt-10 w-4/6 mb-6">
          <template v-if="shareBill && totalPriceTerrain">
            <DoiText
              :textColor="1"
              :fontSize="3"
              :fontFamily="1"
              class="pb-2 tracking-wide md:text-base lg:text-xl"
              >TOTAL À PARTAGER : {{ totalPriceTerrain.toFixed(2) }} €</DoiText
            >
            <div class="w-full md:w-3/6 border-t border-grey"></div>
            <DoiText
              :fontFamily="4"
              :fontSize="0"
              :textColor="0"
              class="flex md:block text-right lg:text-xs"
              >La somme de chaque réservation sera répartie entre les
              participants.</DoiText
            >
          </template>
          <DoiText
            v-if="!shareBill && totalPriceTerrain"
            :textColor="1"
            :fontSize="3"
            :fontFamily="1"
            class="pb-2 tracking-wide md:text-base lg:text-xl"
            >TOTAL : {{ totalPriceTerrain.toFixed(2) }} €</DoiText
          >
        </div>
      </div>
    </div>
    <div v-if="contenuPlace.length > 0">
      <div v-for="data in contenuPlace" :key="data.id">
        <CartPaymentItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :shareBill="shareBill"
          :v2="v2"
        />
      </div>
    </div>
    <div v-if="contenuCours.length > 0">
      <div v-for="(data, index) in contenuCours" :key="data.id">
        <CartPaymentItem
          :readOnly="readOnly"
          @deleteCartElement="deleteCartElement"
          :item="data"
          :shareBill="shareBill"
          @toggleOpen="toggleOpen(index)"
          :index="index"
          :openIndex="openIndex"
          :v2="v2"
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-14 mr-2 sm:w-20"></div>
      <div
        v-if="fullTotal"
        class="flex flex-col items-end justify-end mt-8 w-4/6"
      >
        <DoiText
          :textColor="1"
          :fontSize="3"
          :fontFamily="1"
          class="pb-2 tracking-wide md:text-base lg:text-xl mt-6"
          >TOTAL :
          {{
            (totalPriceCours + totalPricePlace + totalPriceTerrain).toFixed(2)
          }}
          €</DoiText
        >
        <div class="w-5/6 md:w-1/6 border-t border-grey"></div>
      </div>
      <div class="w-1/6"></div>
    </div>
  </div>
</template>

<script>
import CartPaymentItem from "@/views/cart/CartPaymentItem";

export default {
  name: "CartPaymentList",
  data() {
    return {
      openIndex: -1,
      contenu: [],
      contenuTerrain: [],
      contenuPlace: [],
      contenuCours: [],
    };
  },
  props: {
    cartList: {
      type: Object,
      default: {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    shareBill: Boolean,
    v2: String,
  },
  components: { CartPaymentItem },
  async mounted() {
    await this.typeCreneau();
  },
  computed: {
    fullTotal() {
      // Filtrer les trois sous-totaux pour savoir combien sont supérieurs à 0
      // Afficher le total si au moins deux sous-totaux sont supérieurs à 0
      return (
        [
          this.totalPriceTerrain,
          this.totalPriceWithType("place"),
          this.totalPriceWithType("cours"),
        ].filter((item) => item > 0).length >= 2
      );
    },
    totalPrice() {
      return this.cartList.tarif;
    },
    totalPriceTerrain() {
      let total = 0;
      this.contenuTerrain.forEach((item) => {
        if (item.tarif !== 0 && item.nb_place !== 0) total += item.tarif / 100;
      });
      return total;
    },
  },
  methods: {
    totalPriceWithType(type) {
      let total = 0;
      const items = type === "place" ? this.contenuPlace : this.contenuCours;

      items.forEach((item) => {
        total += item.tarif / 100;
      });
      return total;
    },
    toggleOpen(index) {
      this.openIndex = this.openIndex === index ? -1 : index;
    },
    async deleteCartElement(sIdReservation) {
      if (this.cartList.v2) {
        if (this.contenuTerrain.length > 0) {
          this.contenuTerrain.forEach((item, i) => {
            if (item.id === sIdReservation) {
              this.contenuTerrain.splice(i, 1);
            }
          });
        }
        if (this.contenuPlace.length > 0) {
          this.contenuPlace.forEach((item, i) => {
            if (item.id === sIdReservation) {
              this.contenuPlace.splice(i, 1);
            }
          });
        }
        if (this.contenuCours.length > 0) {
          this.contenuCours.forEach((item, i) => {
            if (item.id === sIdReservation) {
              this.contenuCours.splice(i, 1);
            }
          });
        }
      } else {
        this.contenu.forEach((item, i) => {
          if (item.id === sIdReservation) {
            this.contenu.splice(i, 1);
          }
        });
      }
      this.$emit("deleteCartElement", sIdReservation);
    },
    async typeCreneau() {
      this.contenu = this.cartList.contenu ?? [];
      this.cartList.contenu.forEach((item) => {
        if (item.typeCreneau !== undefined) {
          if (item.typeCreneau === "terrain") {
            this.contenuTerrain.push(item);
          } else if (item.typeCreneau === "place") {
            this.contenuPlace.push(item);
          } else if (item.typeCreneau === "cours") {
            this.contenuCours.push(item);
          }
        }
      });
      return this.contenu;
    },
  },
};
</script>
