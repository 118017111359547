<template>
  <div>
    <div class="max-w-6xl mx-auto h-full flex flex-col lg:flex-row">
      <div class="w-full hidden md:block lg:w-3/12">
        <Profil />
      </div>
      <div class="w-full lg:w-9/12">
        <div class="mx-6 mb-2 h-full">
          <div
            class="h-full flex items-center -bottom-11 flex-col bg-white overflow-hidden"
          >
            <div class="w-full m-4 h-full">
              <div v-if="showSection === 'profile' || !showSection">
                <Profil class="block md:hidden" />
                <Booking
                  class="hidden md:block"
                  typeReservation="grand_public"
                />
              </div>
              <div v-if="showSection === 'reservation'">
                <Booking
                  typeReservation="grand_public"
                  :showReservation="showReservation"
                />
              </div>
              <div v-if="showSection === 'video'">
                <ScoreList />
              </div>
              <div v-if="showSection === 'performance'">
                <Performance />
              </div>
              <div v-if="showSection === 'classement'">
                <RankingList />
              </div>
              <div v-if="showSection === 'planning'">
                <PlanningReservation />
              </div>
              <div v-if="showSection === 'reglements'">
                <Reglement />
              </div>
              <div v-if="showSection === 'contacts'">
                <Contact />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Profil from "@/views/user/Profil";
import Booking from "@/views/booking/Booking";
import ScoreList from "@/views/score/ScoreList";
import RankingList from "@/views/ranking/RankingList";
import PlanningReservation from "@/views/planning/PlanningReservation";
import Performance from "@/views/performance/Performance";
import Reglement from "@/views/reglements/Reglement";
import Contact from "@/views/contact/Contact";

export default {
  name: "Dashboard",
  data() {
    return {
      showSection: "reservation",
      showReservation: false,
    };
  },
  components: {
    Profil,
    Booking,
    ScoreList,
    RankingList,
    PlanningReservation,
    Performance,
    Reglement,
    Contact,
  },
  mounted() {
    this.$store.dispatch("CartModule/INIT_CART2");

    if (this.$route.query.q !== undefined)
      this.showSection = this.$route.query.q;

    if (this.$route.query.open === "auto") this.showReservation = "auto";

    localStorage.removeItem("attente_reponse");
    localStorage.removeItem("id_reservation");
    localStorage.removeItem("jeton_invitation");
  },
  computed: {
    getRouteParams() {
      return this.$route.query.q;
    },
  },
  watch: {
    getRouteParams(val) {
      this.showSection = val;
    },
  },
};
</script>
