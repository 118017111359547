<template>
  <div class="relative">
    <Card
      :borderLeftColor="0"
      @click="open = !open"
      class="sm:p-4 flex-col lg:flex-row cursor-pointer"
    >
      <template v-slot:center>
        <div
          class="hidden sm:flex flex-col justify-start items-start py-4 pl-2"
        >
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="5"
            :fontWeight="6"
            class="uppercase"
            >{{ payment.activity }}</DoiText
          >
          <div class="flex flex-col sm:flex-row mt-2">
            <DoiText :fontFamily="0" :textColor="1" :fontSize="3">{{
              formatDate(payment.startDate)
            }}</DoiText>
            <div class="flex items-center">
              <DoiText type="span" class="sm:mx-1 mr-2 w-3 h-3 block bg-red" />
              <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
                payment.location
              }}</DoiText>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col sm:hidden justify-start items-start py-4 pl-2"
        >
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="4"
            :fontWeight="6"
            class="uppercase"
            >{{ payment.activity }}</DoiText
          >
          <div class="flex flex-col sm:flex-row mt-2">
            <DoiText :fontFamily="0" :textColor="1" :fontSize="3">{{
              formatDate(payment.startDate)
            }}</DoiText>
            <div class="flex items-center">
              <DoiText type="span" class="sm:mx-1 mr-2 w-3 h-3 block bg-red" />
              <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
                payment.location
              }}</DoiText>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div class="flex flex-col items-end justify-between pr-2 py-4">
          <DoiText
            :fontFamily="0"
            :fontSize="3"
            class="w-16 p-1 rounded-lg green mb-1 flex justify-center items-center"
            >{{ payment.participantCount }} /
            {{ payment.maxParticipantCount }}</DoiText
          >
          <DoiText :textColor="1" :fontSize="3" :fontFamily="1">{{
            formatPrice(payment.totalAmount)
          }}</DoiText>
        </div>
      </template>
    </Card>
    <div v-if="open" :class="open ? 'border-b border-black' : ''">
      <div class="hidden sm:flex my-4 px-4 justify-between items-center">
        <DoiText :fontFamily="1" :textColor="1" :fontSize="4"
          >{{ payment.participantCount }} /
          {{ payment.maxParticipantCount }} participants</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="4"
          >Prix du terrain : {{ formatPrice(payment.fieldPrice) }}</DoiText
        >
      </div>
      <div class="flex sm:hidden my-4 px-4 justify-between items-center">
        <DoiText :fontFamily="1" :textColor="1" :fontSize="2"
          >{{ payment.maxParticipantCount }} participants</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Prix du terrain : {{ formatPrice(payment.fieldPrice) }}</DoiText
        >
      </div>
      <div
        v-for="(item, index) in payment.payments"
        :key="`paymentItem-${index}`"
      >
        <div
          class="flex justify-between items-center px-4 py-2 payments-details"
        >
          <DoiText
            :fontFamily="0"
            :textColor="1"
            :fontSize="3"
            class="sm:w-60"
            >{{ item.playerName }}</DoiText
          >
          <div
            v-if="
              item.hasOwnProperty('discountCodes') &&
              item.discountCodes.length > 0
            "
          >
            <DoiText
              :fontFamily="0"
              :textColor="1"
              :fontSize="3"
              v-if="item.discountCodes[0].hasOwnProperty('percent')"
              >-{{ item.discountCodes[0].percent }}</DoiText
            >
            <DoiText
              :fontFamily="0"
              :textColor="1"
              :fontSize="3"
              v-if="item.discountCodes[0].hasOwnProperty('amount')"
              >{{ formatPrice(item.discountCodes[0].amount) }}</DoiText
            >
          </div>
          <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
            formatPrice(item.totalPaidAmount)
          }}</DoiText>
        </div>
      </div>
      <DoiText
        :fontFamily="1"
        :textColor="1"
        :fontSize="4"
        class="hidden sm:block text-right mt-4 px-4"
        >Total provisoire : {{ formatPrice(payment.totalAmount) }}</DoiText
      >
      <DoiText
        :fontFamily="1"
        :textColor="1"
        :fontSize="3"
        class="sm:hidden text-right my-4 px-4"
        >Total provisoire : {{ formatPrice(payment.totalAmount) }}</DoiText
      >
      <div class="px-4 mb-4 hidden sm:block">
        <DoiText
          v-if="payment.paymentShare && isOrganizer"
          :fontFamily="0"
          :textColor="1"
          :fontSize="3"
          class="mb-4"
          >Le prix réel à payer peut être inférieur au prix du terrain selon les
          réductions de chaque participant.</DoiText
        >
        <DoiText
          v-if="!payment.paymentShare"
          :fontFamily="0"
          :textColor="1"
          :fontSize="3"
          class="mb-4"
          >Le paiement de cette séance est pris en charge par l'organisateur. Le
          montant associé à votre participation est indicatif, aucun prélèvement
          ne sera effectué par Fit Arena.</DoiText
        >
        <DoiText
          v-if="payment.paymentShare && !isOrganizer"
          :fontFamily="0"
          :textColor="1"
          :fontSize="3"
          class="mb-4"
          >Les montants indiqués sont provisoires. Le prix réel à payer sera
          fixé après votre séance et correspondra au prix du terrain divisé par
          le nombre de participants.</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >Prélèvement prévu le {{ formatDate(payment.orderDate) }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >Par carte bancaire : {{ payment.cardPan }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="3"
          >UUID réservation : {{ payment.uuidReservation }}</DoiText
        >
      </div>
      <div class="px-4 mb-4 sm:hidden">
        <DoiText
          v-if="payment.paymentShare && isOrganizer"
          :fontFamily="0"
          :textColor="1"
          :fontSize="3"
          class="mb-4"
          >Le prix réel à payer peut être inférieur au prix du terrain selon les
          réductions de chaque participant.</DoiText
        >
        <DoiText
          v-if="!payment.paymentShare"
          :fontFamily="0"
          :textColor="1"
          :fontSize="3"
          class="mb-4"
          >Le paiement de cette séance est pris en charge par l'organisateur. Le
          montant associé à votre participation est indicatif, aucun prélèvement
          ne sera effectué par Fit Arena.</DoiText
        >
        <DoiText
          v-if="payment.paymentShare && !isOrganizer"
          :fontFamily="0"
          :textColor="1"
          :fontSize="3"
          class="mb-4"
          >Les montants indiqués sont provisoires. Le prix réel à payer sera
          fixé après votre séance et correspondra au prix du terrain divisé par
          le nombre de participants.</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Prélèvement prévu le {{ formatDate(payment.orderDate) }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >Par carte bancaire : {{ payment.cardPan }}</DoiText
        >
        <DoiText :fontFamily="0" :textColor="1" :fontSize="2"
          >UUID réservation : {{ payment.uuidReservation }}</DoiText
        >
      </div>
    </div>
    <div :class="open === false ? 'border-t border-black' : ''">
      <div
        @click="open = !open"
        class="bg-black absolute right-0 px-5 py-2 flex items-center justify-between cursor-pointer w-full lg:w-4/12 lg:-bottom-10 border-button"
      >
        <DoiText :fontFamily="4" class="uppercase tracking-wider"
          >DÉTAILS</DoiText
        >
        <DoiText class="material-icons">{{
          open === false ? "keyboard_arrow_down" : "keyboard_arrow_up"
        }}</DoiText>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/atomic/Card";

import dayjs from "dayjs";

export default {
  name: "PaymentToComeListItem",
  components: { Card },
  data() {
    return {
      open: false,
    };
  },
  props: {
    isOdd: {
      type: Boolean,
      default: false,
    },
    payment: Object,
  },
  computed: {
    isOrganizer() {
      return (
        this.payment.organizerId ===
        this.$store.getters["UserModule/get_contact_id"]
      );
    },
  },
  methods: {
    formatDate(startDate) {
      return dayjs(startDate).format("DD-MM-YYYY" + " à " + "HH[h]mm");
    },
    formatPrice(price) {
      return Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(price / 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.green {
  background-color: $doi--color-color2;
}

.bg-red {
  background-color: $doi--color-color0;
}

.payments-details {
  border-bottom: 1px solid black;
}
</style>
