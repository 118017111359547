<template>
  <div>
    <div class="flex flex-row bg-gray-200 rounded-t ranking-border">
      <div class="img-icon items-center flex justify-center">
        <img
          :src="require(`@/assets/icons/utils/sports/${icon(rank.typeSport)}`)"
        />
      </div>
      <div class="flex items-center w-1/2">
        <DoiText
          class="ml-6 sm:ml-10 sm:text-2xl lg:text-3xl sport-label"
          :textColor="1"
          :fontSize="5"
          :fontFamily="4"
          >{{ sportLabel }}</DoiText
        >
      </div>
      <div class="flex items-center w-1/2 relative">
        <DoiText
          :textColor="1"
          :fontFamily="5"
          class="absolute hidden right-10 sm:block"
          >VOTRE POSITION :
          <DoiText
            type="span"
            :textColor="0"
            :fontFamily="5"
            class="lg:text-3xl"
            >{{ rank.position }}</DoiText
          ></DoiText
        >
        <DoiText
          :textColor="1"
          :fontFamily="5"
          class="absolute right-5 sm:hidden"
          >POS. :
          <DoiText type="span" :textColor="0" :fontFamily="5">{{
            rank.position
          }}</DoiText></DoiText
        >
      </div>
    </div>
    <div class="overflow-x-scroll">
      <DoiTableNew>
        <template slot="table-head">
          <DoiTableHead class="pos" :bgColorTable="3" :fontFamily="4"
            >POS.</DoiTableHead
          >
          <DoiTableHead :bgColorTable="3" :fontFamily="4" class="text-left"
            >JOUEUR</DoiTableHead
          >
          <DoiTableHead :bgColorTable="3" :fontFamily="4" class="text-left"
            >MATCHS JOUÉS</DoiTableHead
          >
          <DoiTableHead :bgColorTable="0" :fontFamily="4" class="text-left"
            >VICTOIRES</DoiTableHead
          >
          <DoiTableHead :bgColorTable="3" :fontFamily="4" class="text-left"
            >NULS</DoiTableHead
          >
          <DoiTableHead :bgColorTable="1" :fontFamily="4" class="text-left"
            >DÉFAITES</DoiTableHead
          >
          <DoiTableHead :bgColorTable="2" :fontFamily="4" class="text-left"
            >RATIO V</DoiTableHead
          >
        </template>
        <template slot="table-body">
          <template v-for="(rankingPos, index) in rankList">
            <template
              v-if="
                expanded && index === 3 && !isShortRanking && rank.position > 4
              "
            >
              <tr class="w-full relative h-10" :key="index">
                <td colspan="7">
                  <div class="flex w-full justify-center items-center">
                    <span class="dot"></span> <span class="dot"></span>
                    <span class="dot"></span>
                  </div>
                </td>
              </tr>
            </template>
            <DoiTableRow
              v-if="isShortRanking || rankingPos.position <= 3 || expanded"
              :key="index"
              :class="rankingPos.idContact === contact_id ? 'bg-gray-200' : ''"
            >
              <DoiTableCell
                class="pos text-center"
                :class="
                  rankingPos.position <= 3
                    ? 'text-4xl flex justify-center items-end'
                    : ''
                "
                :textColor="rankingPos.position <= 3 ? 2 : 1"
                :fontSize="3"
                :fontFamily="2"
                :bgColorTable="getColorRank(rankingPos.position)"
              >
                {{ rankingPos.position }}
                <svg
                  v-if="rankingPos.position <= 3"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18 2c-.9 0-2 1-2 2H8c0-1-1.1-2-2-2H2v9c0 1 1 2 2 2h2.2c.4 2 1.7 3.7 4.8 4v2.08C8 19.54 8 22 8 22h8s0-2.46-3-2.92V17c3.1-.3 4.4-2 4.8-4H20c1 0 2-1 2-2V2h-4M6 11H4V4h2v7m14 0h-2V4h2v7Z"
                  />
                </svg>
              </DoiTableCell>
              <DoiTableCell :textColor="1" :fontFamily="10"
                >{{ rankingPos.nom }} {{ rankingPos.prenom }}</DoiTableCell
              >
              <DoiTableCell :textColor="1" :fontFamily="10">{{
                rankingPos.nbMatch
              }}</DoiTableCell>
              <DoiTableCell :textColor="0" :fontFamily="10">{{
                rankingPos.victoire
              }}</DoiTableCell>
              <DoiTableCell :textColor="1" :fontFamily="10">{{
                rankingPos.nul
              }}</DoiTableCell>
              <DoiTableCell :textColor="6" :fontFamily="10">{{
                rankingPos.defaite
              }}</DoiTableCell>
              <DoiTableCell :textColor="4" :fontFamily="10">{{
                ratioV(rankingPos.victoire, rankingPos.nbMatch)
              }}</DoiTableCell>
            </DoiTableRow>
          </template>
        </template>
      </DoiTableNew>
    </div>
    <div
      class="bg-red justify-end content-center tracking-wider flex border-b-2 border-gray py-2"
    >
      <DoiText
        :fontSize="4"
        :fontFamily="0"
        :fontWeight="5"
        class="text-right pr-6 tracking-widest"
        >SHARE !</DoiText
      >
      <div
        class="flex sm:hidden cursor-pointer border-white border rounded mr-4"
        @click="share()"
      >
        <img src="@/assets/icons/common/share.svg" class="px-2 w-9" />
      </div>
      <ShareNetwork
        class="hidden sm:block"
        network="facebook"
        :url="`${getLocation()}share/partie/${setCode}`"
        :quote="`Mon classement - ${sportLabel}`"
        title=""
        hashtags="FitArena"
        ><img
          class="pr-4 pt-1 w-11"
          src="@/assets/img/share/facebook.png"
          alt="Facebook Fit Arena"
        />
      </ShareNetwork>
      <ShareNetwork
        class="hidden sm:block"
        network="twitter"
        :url="`${getLocation()}share/partie/${setCode}`"
        :title="`Mon classement - ${sportLabel}`"
        hashtags="FitArena"
        ><img
          class="pr-4 pt-1 w-11"
          src="@/assets/img/share/twitter.png"
          alt="Twitter Fit Arena"
        />
      </ShareNetwork>
    </div>
    <DoiAccordionButton
      v-if="rank.joueur.length > 3"
      primaryText="DÉTAILS"
      :open="expanded"
      @toggle="expanded = !expanded"
    />
  </div>
</template>

<script>
import sports from "@/data/sports.json";
import { getPathSportIconById, getSportLabel } from "@/utils/sports.js";

export default {
  name: "RankingListItem",
  data() {
    return {
      playerPosition: [Number, String],
      expanded: false,
    };
  },
  props: {
    sportId: {
      type: String,
      required: true,
    },
    rank: Object,
    open: {},
    contact_id: Number,
  },
  computed: {
    rankList() {
      return this.rank.joueur;
    },
    setCode() {
      let code = `${this.sportIcon}&${this.sportLabel}&${this.rank.position}&`;

      for (let i = 0; i < this.rankList.length; i++) {
        code += `${this.rankList[i].position}&${this.rankList[i].nom}&${this.rankList[i].prenom}&${this.rankList[i].nbMatch}&${this.rankList[i].victoire}&${this.rankList[i].nul}&${this.rankList[i].defaite}&`;
      }
      return btoa(code);
    },
    sportIcon() {
      return getPathSportIconById(this.rank.idSport);
    },
    sportLabel() {
      return getSportLabel(this.rank.idSport);
    },
    isShortRanking() {
      return this.rankList.length < 4;
    },
  },
  methods: {
    icon(sportName) {
      let icon = "";
      const sp = sports.find(
        (sport) => sport.label === sportName.toLowerCase()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    getColorRank(pos) {
      if (pos === 1) return 2;
      if (pos === 2) return 5;
      if (pos === 3) return 4;
      return;
    },
    ratioV(victoire, nbMatch) {
      return (victoire / nbMatch).toFixed(2);
    },
    share() {
      if (!window.navigator.share) {
        this.$toast.error("Navigateur non compatible.");
      } else {
        const data = {
          title: `Mon classement - ${sportLabel}`,
          text: "Venez voir mon classement Fit Arena",
          url: `${getLocation()}share/partie/${setCode}`,
        };
        window.navigator.share(data);
      }
    },
    getLocation() {
      return process.env.VUE_APP_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-red {
  background-color: $doi--color-color0;
}

.border-gray {
  border: 1px solid $doi--color-color4;
}

.img-icon {
  width: 3.75rem;
  min-width: 3.75rem;
}

.pos {
  max-width: 3.75rem;
  width: 3.75rem;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: $doi--color-color0;
  border-radius: 50%;
  margin-right: 0.5rem;
  display: inline-block;
}

@media (min-width: $doi-breakpoint-large-desktop) and (max-width: $doi-breakpoint-extra-large-desktop) {
  .img-icon {
    width: 4.25rem;
    min-width: 4.25rem;
  }

  .pos {
    max-width: 4.25rem;
    width: 4.25rem;
  }
}
@media (min-width: $doi-breakpoint-mobile) and (max-width: 767px) {
  .img-icon {
    width: 3.25rem;
    min-width: 3.25rem;
  }
  .pos {
    max-width: 3.25rem;
    width: 3.25rem;
  }
}
@media (min-width: 320px) and (max-width: 639px) {
  .img-icon {
    width: 2.75rem;
    min-width: 2.75rem;
  }
  .pos {
    max-width: 2.75rem;
    width: 2.75rem;
  }

  .sport-label {
    font-size: $doi--text-font-size2;
  }
}
</style>
