<template>
  <div class="w-full">
    <div class="flex justify-start md:justify-center w-full mb-6">
      <DoiHeader
        :level="3"
        :fontFamily="1"
        class="text-2xl md:text-4xl tracking-wide"
        >MES PERFORMANCES</DoiHeader
      >
    </div>
    <div v-if="!isLoaded" class="w-full flex justify-center">
      <DoiSpinner :spinnerSize="1" />
    </div>
    <div v-else-if="performances.length === 0" class="text-center mx-auto">
      <DoiText :textColor="3" :fontSize="4"
        >Vos performances ne seront accessibles qu’après votre première
        partie.</DoiText
      >
    </div>
    <div v-else>
      <!-- premiere section recapitulative du temps passe et du nombre de seances -->
      <div class="flex items-center justify-between mb-8">
        <div class="flex border-b-2 sport-sessions">
          <div class="w-20 h-20 sm:h-28 pr-2">
            <img src="@/assets/icons/utils/sports/sports.svg" alt="" />
          </div>
          <div>
            <DoiText :textColor="3" :fontSize="1" class="sm:text-lg"
              >NOMBRE DE SÉANCES</DoiText
            >
            <DoiText
              :textColor="0"
              :fontSize="4"
              :fontFamily="1"
              class="sm:text-4xl"
              >{{ this.performances[0].totaux[0].nbTotal }}</DoiText
            >
          </div>
        </div>
        <div class="flex border-b-2 time-sessions">
          <div class="w-20 h-20 sm:h-28 pr-2">
            <img src="@/assets/icons/utils/sports/clock.svg" alt="" />
          </div>
          <div class="flex-col">
            <DoiText :textColor="3" :fontSize="1" class="sm:text-lg"
              >TEMPS TOTAL PASSÉ</DoiText
            >
            <DoiText
              :textColor="0"
              :fontSize="4"
              :fontFamily="1"
              class="sm:text-4xl uppercase"
              >{{
                formatPlayTime(this.performances[0].totaux[0].tempsTotal)
              }}</DoiText
            >
          </div>
        </div>
      </div>
      <!-- deuxieme section des top 3 des salles visitees et des sports pratiques -->
      <div class="bg-lightgray py-6 px-4 mb-8">
        <!-- salles visitees -->
        <DoiText :textColor="0" :fontSize="4"
          >SALLES LES PLUS VISITÉES :</DoiText
        >
        <div class="flex flex-col flex-wrap sm:flex-row justify-between mb-8">
          <div
            v-for="(salle, index) in performances[0].salles"
            :key="index"
            class="hall-fit-arena mt-2 border-b-2 pb-4 sm:pb-0"
          >
            <div class="flex items-center">
              <DoiText
                id="sport-index"
                class="uppercase flex items-center mr-3"
                :textColor="1"
                :fontFamily="1"
                :fontSize="6"
                >{{ index + 1 }}</DoiText
              >
              <div>
                <DoiText
                  :textColor="1"
                  :fontFamily="5"
                  class="text-lg md:text-xl"
                  >{{ salle.nom }}</DoiText
                >
                <DoiText :textColor="0" :fontSize="3">{{
                  salle.adresse
                }}</DoiText>
              </div>
            </div>
          </div>
        </div>
        <!-- sports pratiques -->
        <DoiText :textColor="0" :fontSize="4"
          >SPORTS LES PLUS PRATIQUÉS :</DoiText
        >
        <div class="flex flex-col flex-wrap sm:flex-row justify-between">
          <div
            v-for="(sport, index) in performances[0].sports"
            :key="index"
            class="flex w-1/3 hall-fit-arena mt-2"
          >
            <div class="pl-2 border-b-2 flex-row flex sport-fit-arena">
              <DoiText
                id="sport-index"
                class="uppercase flex items-center"
                :textColor="1"
                :fontFamily="1"
                :fontSize="5"
                >{{ index + 1 }}</DoiText
              >
              <div class="sport-performance-icon w-14 h-14 self-end flex ml-6">
                <img
                  :src="
                    require(`@/assets/icons/utils/sports/${icon(
                      sport.typeSport
                    )}`)
                  "
                />
              </div>
            </div>
            <div class="pl-4 flex self-center pt-4">
              <DoiText :textColor="3" :fontSize="3" class="xl:text-base">
                <DoiText
                  type="span"
                  :textColor="0"
                  :fontSize="3"
                  class="xl:text-base"
                  >{{ sport.nbMatch }}</DoiText
                >
                {{ sport.nbMatch === 1 ? "SESSION" : "SESSIONS" }}
              </DoiText>
            </div>
          </div>
        </div>
      </div>
      <!-- accordeon par sport -->
      <div
        v-for="(sport, index) in performances[0].sports"
        :key="index"
        class="mb-8"
      >
        <PerformanceSportItem
          @toggleOpen="toggleOpen(index)"
          :sport="sport"
          :index="index"
          :openIndex="openIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sports from "@/data/sports.json";
import PerformanceSportItem from "@/views/performance/PerformanceSportItem";
import { getPathSportIconById, getSportLabel } from "@/utils/sports.js";
import { getPerformances } from "@/api/StatisticService";

export default {
  name: "Performance",
  components: {
    PerformanceSportItem,
  },
  data() {
    return {
      openIndex: -1,
      performances: [],
      isLoaded: false,
    };
  },
  mounted() {
    this.getPerformances();
  },
  computed: {
    sportIcon() {
      return this.getPathSportIconById(this.performances);
    },
    contact_id() {
      return this.$store.getters["UserModule/get_contact_id"];
    },
  },
  methods: {
    icon(sportName) {
      let icon = "";
      const sp = sports.find(
        (sport) => sport.label === sportName.toLowerCase()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    sportLabel(id_sport) {
      return getSportLabel(id_sport);
    },
    async getPerformances() {
      this.performances = await getPerformances(this.contact_id);
      this.isLoaded = true;
    },
    getPathSportIconById(id) {
      return getPathSportIconById(id);
    },
    toggleOpen(index) {
      this.openIndex = this.openIndex === index ? -1 : index;
    },
    formatPlayTime(time) {
      let sec_num = parseInt(time, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      return hours + "h" + minutes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.bg-lightgray {
  background: $doi-bg-lightgrey-color;
  border-radius: 4px;
}

.sport-sessions,
.time-sessions {
  width: 49%;
}

.hall-fit-arena {
  width: 31%;
}

.sport-fit-arena {
  min-height: 75px;
}

#sport-index {
  min-width: 0.6em;
}

@media (max-width: 639px) {
  .hall-fit-arena {
    width: 100%;
  }
}
</style>
