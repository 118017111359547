<template>
  <div
    v-if="
      equipe_name == 'Non joueur' ||
      equipe_name == 'Spectateurs' ||
      equipe_name == 'Spectateur'
    "
    class="w-full mb-6 flex flex-col"
  >
    <div class="flex flex-1 justify-center max-h-8">
      <DoiText :textColor="1" :fontSize="4" class="uppercase mr-4">{{
        equipe_name
      }}</DoiText>
      <span class="flex flex-1 items-center"><span class="line" /></span>
    </div>
    <div class="mt-2">
      <table class="w-full table-fixed">
        <thead>
          <tr>
            <th class="w-3/12 md:w-2/12"></th>
            <th class="w-9/12 md:w-10/12"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in team" :key="index">
            <td class="pl-2">
              <span>
                <img
                  class="rounded-full h-10 w-10 object-cover"
                  width="10"
                  :src="getAvatar(player.idContact)"
                  @error="getImgError"
                />
              </span>
            </td>
            <td class="pl-2">{{ player.nomJoueur }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import defaultAvatar from "@/assets/img/no-avatar.png";

export default {
  name: "ScoreDetailNonPlayerItemSet",
  props: {
    team: Array,
  },
  computed: {
    equipe_name() {
      return this.team[0].nomEquipe.length < 15
        ? this.team[0].nomEquipe
        : `${this.team[0].nomEquipe.slice(0, 15)}...`;
    },
  },
  methods: {
    getAvatar(id) {
      return `${process.env.VUE_APP_BACK}document/membre/avatar/contact/${id}/visualise-document.html`;
    },
    getImgError(e) {
      e.target.src = defaultAvatar;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

th {
  font-family: $doi--text-font-family4;
}

.line {
  height: 2px;
  width: 90%;
  background-color: $doi--color-color0;
}
</style>
