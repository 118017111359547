<template>
  <div>
    <DoiCard class="flex-row rounded-t">
      <template v-slot:left>
        <div class="w-14 h-14 items-center flex justify-center">
          <img
            :src="
              require(`@/assets/icons/utils/sports/${icon(sport.typeSport)}`)
            "
          />
        </div>
      </template>
      <template v-slot:center>
        <div class="flex items-center justify-start pl-6">
          <DoiText
            :textColor="1"
            :fontSize="5"
            :fontFamily="1"
            class="uppercase md:text-3xl sport-label"
            >{{ sportLabel }}</DoiText
          >
        </div>
      </template>
      <template v-slot:right>
        <div class="flex items-center px-4">
          <DoiText
            v-if="index === 0"
            :textColor="4"
            :fontSize="6"
            :fontFamily="1"
            >{{ index + 1 }}</DoiText
          >
          <DoiText
            v-else-if="index === 1"
            :textColor="3"
            :fontSize="6"
            :fontFamily="1"
            >{{ index + 1 }}</DoiText
          >
          <DoiText v-else :textColor="5" :fontSize="6" :fontFamily="1">{{
            index + 1
          }}</DoiText>
        </div>
      </template>
    </DoiCard>
    <div v-if="openIndex === index">
      <div
        id="test"
        class="flex flex-col sm:flex-row sm:text-base md:text-lg px-6"
      >
        <div class="py-6 sm:w-5/12">
          <DoiText
            v-if="
              sport.typeSport == 'Volleyball' ||
              sport.typeSport == 'Padel' ||
              sport.typeSport == 'Badminton' ||
              sport.typeSport == 'Ping Pong'
            "
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="border-b py-2"
            >Nombre de parties jouées :
            <DoiText type="span" :fontFamily="1" :textColor="1" :fontSize="4">{{
              sport.nbMatch
            }}</DoiText></DoiText
          >
          <DoiText
            v-else
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="border-b py-2"
            >Nombre de matchs joués :
            <DoiText type="span" :fontFamily="1" :textColor="1" :fontSize="4">{{
              sport.nbMatch
            }}</DoiText></DoiText
          >
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="border-b py-2"
            >Victoire :
            <DoiText type="span" :fontFamily="1" :textColor="1" :fontSize="4">{{
              sport.victoires
            }}</DoiText></DoiText
          >
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="border-b py-2"
            >Nul :
            <DoiText type="span" :fontFamily="1" :textColor="1" :fontSize="4">{{
              sport.nul
            }}</DoiText></DoiText
          >
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="border-b py-2"
            >Défaite :
            <DoiText type="span" :fontFamily="1" :textColor="1" :fontSize="4">{{
              sport.defaites
            }}</DoiText></DoiText
          >
          <DoiText
            :textColor="1"
            :fontFamily="0"
            :fontSize="3"
            class="border-b py-2"
            >Temps de jeu :
            <DoiText type="span" :fontFamily="1" :textColor="1" :fontSize="4">{{
              formatPlayTime
            }}</DoiText></DoiText
          >
        </div>
        <div class="flex text-center ratio mb-6 sm:mt-10 sm:ml-12">
          <ProgressCircle :percent="percentLooseWin">
            <div class="flex flex-col">
              <DoiText
                :fontSize="3"
                :textColor="1"
                :fontFamily="1"
                class="text-xl mt-8 md:mt-10 md:mb-4"
                >RATIO V/D</DoiText
              >
              <DoiText
                :textColor="0"
                :fontSize="5"
                :fontFamily="1"
                class="-mt-2 sm:mt-2"
                >{{ ratioWinLooseFormatted }}</DoiText
              >
            </div>
          </ProgressCircle>
          <div class="flex vdn-number ml-3 sm:text-xl">
            <div class="flex flex-col justify-center items-start">
              <DoiText
                :textColor="0"
                :fontFamily="1"
                :fontSize="3"
                class="border-l py-2 pl-4 md:text-xl"
                >VICTOIRE</DoiText
              >
              <DoiText
                :textColor="6"
                :fontFamily="1"
                :fontSize="3"
                class="border-l pb-2 pl-4 md:text-xl"
                >DÉFAITE</DoiText
              >
              <DoiText
                :textColor="3"
                :fontFamily="1"
                :fontSize="3"
                class="border-l pb-2 pl-4 md:text-xl"
                >NUL</DoiText
              >
            </div>
            <div class="flex flex-col justify-center items-center">
              <DoiText
                :textColor="0"
                :fontFamily="1"
                :fontSize="3"
                class="py-2 pl-2 md:text-xl"
                >{{ sport.victoires }}</DoiText
              >
              <DoiText
                :textColor="6"
                :fontFamily="1"
                :fontSize="3"
                class="pb-2 pl-2 md:text-xl"
                >{{ sport.defaites }}</DoiText
              >
              <DoiText
                :textColor="3"
                :fontFamily="1"
                :fontSize="3"
                class="pb-2 pl-2 md:text-xl"
                >{{ sport.nul }}</DoiText
              >
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          sport.typeSport == 'Basket 5x5' || sport.typeSport == 'Basket 3x3'
        "
        class="bg-gray text-sm px-6 py-2 sm:text-lg"
      >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre total de paniers marqués :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            sport.nbButsMarques
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre moyen de paniers marqués par match :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            averageGoals
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre total de fautes :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            sport.nbFautes
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre moyen de fautes par match :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            averageFouls
          }}</DoiText></DoiText
        >
      </div>
      <div
        v-else-if="
          sport.typeSport == 'Volleyball' ||
          sport.typeSport == 'Padel' ||
          sport.typeSport == 'Badminton' ||
          sport.typeSport == 'Ping Pong'
        "
        class="bg-gray text-sm px-6 py-2 sm:text-lg"
      >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre total de sets gagnés :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            sport.nbButsMarques
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre de sets gagnés par match :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            averageGoals
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre total de jeux gagnés :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            sport.nbJeuxGagnes
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre moyen de jeu par set :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            sport.nbJeuxParSet
          }}</DoiText></DoiText
        >
      </div>
      <div v-else class="bg-gray text-sm px-6 py-2 sm:text-lg">
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre total de buts marqués :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            sport.nbButsMarques
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre moyen de buts marqués par match :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            averageGoals
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre total de fautes :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            sport.nbFautes
          }}</DoiText></DoiText
        >
        <DoiText :textColor="1" :fontSize="3" :fontFamily="0" class="py-2"
          >Nombre moyen de fautes par match :
          <DoiText type="span" :textColor="1" :fontSize="4" :fontFamily="1">{{
            averageFouls
          }}</DoiText></DoiText
        >
      </div>

      <div
        class="bg-red justify-end content-center tracking-wider flex border-b-2 border-gray py-2"
      >
        <DoiText
          :fontSize="4"
          :fontFamily="0"
          :fontWeight="5"
          class="text-right pr-6 tracking-widest"
          >SHARE !</DoiText
        >
        <div
          class="flex sm:hidden cursor-pointer border-white border rounded mr-4"
          @click="share()"
        >
          <img src="@/assets/icons/common/share.svg" class="px-2 w-9" />
        </div>
        <ShareNetwork
          class="hidden sm:block"
          network="facebook"
          :url="`${getLocation()}share/performance/${setCode}`"
          :quote="`Mes performances - ${sportLabel}`"
          title=""
          hashtags="FitArena"
          ><img
            class="pr-4 pt-1 w-11"
            src="@/assets/img/share/facebook.png"
            alt="Facebook Fit Arena"
          />
        </ShareNetwork>
        <ShareNetwork
          class="hidden sm:block"
          network="twitter"
          :url="`${getLocation()}share/performance/${setCode}`"
          :title="`Mes performances - ${sportLabel}`"
          hashtags="FitArena"
          ><img
            class="pr-4 pt-1 w-11"
            src="@/assets/img/share/twitter.png"
            alt="Twitter Fit Arena"
          />
        </ShareNetwork>
      </div>
    </div>
    <DoiAccordionButton
      primaryText="Détails"
      :open="openIndex === index"
      @toggle="$emit('toggleOpen')"
    />
  </div>
</template>

<script>
import sports from "@/data/sports.json";
import ProgressCircle from "@/components/atomic/ProgressCircle";
import { getPathSportIconById, getSportLabel } from "@/utils/sports.js";
import { mapGetters } from "vuex";

export default {
  name: "PerformanceSportItem",
  components: {
    ProgressCircle,
  },
  props: {
    sport: Object,
    index: Number,
    openIndex: Number,
  },
  computed: {
    ...mapGetters("UserModule", ["get_user_id"]),
    sportIcon() {
      return getPathSportIconById(this.sport.idSport);
    },
    sportLabel() {
      return getSportLabel(this.sport.idSport);
    },
    percentLooseWin() {
      if (this.sport.nbMatch > 0)
        return (this.sport.victoires / this.sport.nbMatch) * 100;
      return 0;
    },
    ratioWinLooseFormatted() {
      if (this.sport.nbMatch > 0 && this.sport.victoires != 0) {
        if (this.sport.defaites != 0)
          return (this.sport.victoires / this.sport.defaites).toFixed(2);
        else return this.sport.victoires;
      }
      return 0;
    },
    averageGoals() {
      return (this.sport.nbButsMarques / this.sport.nbMatch).toFixed(2);
    },
    averageFouls() {
      return (this.sport.nbFautes / this.sport.nbMatch).toFixed(2);
    },
    formatPlayTime() {
      let sec_num = parseInt(this.sport.tempsDeJeu, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      return hours + "h" + minutes;
    },
    setCode() {
      let code =
        `${this.sportLabel}&${this.sport.nbMatch}&${this.sport.victoires}&${this.sport.nul}&${this.sport.defaites}&` +
        `${this.formatPlayTime}&${this.percentLooseWin}&${
          this.ratioWinLooseFormatted
        }&${this.setPercentage("win")}&${this.setPercentage("defeat")}&` +
        `${this.setPercentage("draw")}&${this.sport.nbButsMarques}&${
          this.averageGoals
        }&${this.sport.nbFautes}&${this.averageFouls}`;
      return btoa(code);
    },
  },
  methods: {
    setPourcentage(type) {
      let item;
      switch (type) {
        case "win":
          item = this.sport.victoires;
          break;
        case "defeat":
          item = this.sport.defaites;
          break;
        case "draw":
          item = this.sport.nul;
          break;
      }
      return Math.round((item / this.sport.nbMatch) * 100);
    },
    icon(sportName) {
      let icon = "";
      const sp = sports.find(
        (sport) => sport.label === sportName.toLowerCase()
      );
      if (sp !== undefined) icon = sp.icon;
      else icon = "Fitness.svg";
      return icon;
    },
    share() {
      if (!window.navigator.share) {
        this.$toast.error("Navigateur non compatible.", { position: "top" });
      } else {
        const data = {
          title: "Performances Fit Arena",
          text: "Venez voir mes performances Fit Arena",
          url: `${getLocation()}share/performance/${setCode}`,
        };
        window.navigator.share(data);
      }
    },
    getLocation() {
      return process.env.VUE_APP_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-red {
  background-color: $doi--color-color0;
}

.bg-gray {
  background-color: $doi--card-color0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.border-gray {
  border-bottom: 1px solid $doi--color-color4;
}

@media (max-width: 374px) {
  .ratio {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .vdn-number {
    margin: 1rem auto;
  }
}

@media (max-width: 450px) {
  .sport-label {
    font-size: 1rem;
  }
}
</style>
