<template>
  <div class="flex w-full justify-between items-center">
    <div class="flex flex-shrink-0">
      <DoiImage
        size="3.5rem"
        :src="contactAvatar"
        class="mx-auto rounded-full border-4 w-12 h-12"
        :class="colorClass"
      />
    </div>
    <div class="flex flex-col flex-1 mx-1 truncate">
      <DoiText :textColor="3" :fontSize="3" class="contact-info"
        >{{ contact.prenom }} {{ contact.nom }}</DoiText
      >
      <DoiText :textColor="0" :fontFamily="4" class="contact-info">{{
        contact.pseudo
      }}</DoiText>
      <DoiText
        v-if="contact.concerne_par_resa == true"
        :textColor="0"
        :fontFamily="4"
        class="contact-info"
        >Organisateur</DoiText
      >
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
import { generateImageUrl } from "@/utils/imgUrlBuilder";

export default {
  name: "ContactInfo",
  props: {
    contact: Object,
  },
  computed: {
    contactAvatar() {
      return this.contact.url_avatar !== "" &&
        this.contact.url_avatar !== undefined
        ? generateImageUrl(
            this.contact.url_avatar + "?d=" + this.contact.tampon_modif_avatar
          )
        : require("@/assets/img/no-avatar.png");
    },
    colorClass() {
      if (this.contact.reponse === 2) return "border-green";
      else if (this.contact.reponse === 1) return "border-red";
      return "border-grey";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.border-green {
  border-color: $doi--color-color2;
}
.border-red {
  border-color: $doi--color-color0;
}
.border-grey {
  border-color: $doi--color-color-lighter4;
}

.contact-info {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
</style>
