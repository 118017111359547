<template>
  <div>
    <div class="my-2 md:my-4 flex flex-col w-full">
      <div class="flex w-full items-center justify-center">
        <div @click="afficherAction = !afficherAction" class="w-1/6">
          <div v-if="tempsFortsActionSet.action.length > 0">
            <svg
              :class="
                afficherAction ? 'transform rotate-180 justify-start' : ''
              "
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4Z"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col w-4/6 justify-center">
          <div class="flex items-center">
            <DoiText
              v-if="
                tempsFortsActionSet.scoreEquipe1 >
                  tempsFortsActionSet.scoreEquipe2 ||
                tempsFortsActionSet.scoreEquipe1 ==
                  tempsFortsActionSet.scoreEquipe2
              "
              :textColor="0"
              :fontSize="4"
              >{{ tempsFortsActionSet.scoreEquipe1 }}</DoiText
            >
            <DoiText v-else :textColor="1" :fontSize="4">{{
              tempsFortsActionSet.scoreEquipe1
            }}</DoiText>
            <span class="flex flex-1 items-center"
              ><span class="line-score"
            /></span>
            <DoiText :textColor="1" :fontSize="3" :fontFamily="2"
              >SET {{ getPosition(tempsFortsActionSet.id) + 1 }}</DoiText
            >
            <span class="flex flex-1 items-center"
              ><span class="line-score"
            /></span>
            <DoiText
              v-if="
                tempsFortsActionSet.scoreEquipe2 >
                  tempsFortsActionSet.scoreEquipe1 ||
                tempsFortsActionSet.scoreEquipe1 ==
                  tempsFortsActionSet.scoreEquipe2
              "
              :textColor="0"
              :fontSize="4"
              >{{ tempsFortsActionSet.scoreEquipe2 }}</DoiText
            >
            <DoiText v-else :textColor="1" :fontSize="4">{{
              tempsFortsActionSet.scoreEquipe2
            }}</DoiText>
          </div>
        </div>
        <div class="flex justify-center w-1/6">
          <div
            v-if="
              tempsFortsActionSet.videos &&
              tempsFortsActionSet.videos.length > 0
            "
            class="flex items-center"
          >
            <div
              @click="
                $emit(
                  'setVideo',
                  tempsFortsActionSet.videos[0].url,
                  tempsFortsActionSet.videos[0].urlMiniature,
                  index
                )
              "
              class="cursor-pointer flex items-center justify-center w-8 h-8 ml-2 bg-fa-red"
            >
              <img src="@/assets/icons/common/play.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="afficherAction">
      <div
        v-for="(action, i) in tempsFortsActionSet.action"
        :key="i"
        class="flex space-y-2 items-center"
      >
        <div class="flex w-1/6">
          <DoiText
            v-if="action.instant < 60"
            :textColor="3"
            :fontFamily="1"
            :fontSize="3"
            >{{ action.instant }}''</DoiText
          >
          <DoiText v-else :textColor="3" :fontFamily="1" :fontSize="3"
            >{{ Math.trunc(action.instant / 60) }}'</DoiText
          >
        </div>
        <div class="flex w-4/6 items-center justify-center">
          <div class="flex items-center justify-between w-full">
            <div class="flex w-2/6">
              <DoiText
                v-if="
                  action.scoreEquipe1 > action.scoreEquipe2 ||
                  action.scoreEquipe1 == action.scoreEquipe2
                "
                :textColor="0"
                :fontSize="4"
                >{{ action.scoreEquipe1 }}</DoiText
              >
              <DoiText v-else :textColor="1" :fontSize="4">{{
                action.scoreEquipe1
              }}</DoiText>
              <span class="flex flex-1 items-center"
                ><span class="line-score"
              /></span>
            </div>
            <div class="flex w-2/6 justify-center">
              <DoiText
                :textColor="1"
                :fontSize="3"
                :fontFamily="5"
                class="truncate"
                >ACTION {{ i + 1 }}</DoiText
              >
            </div>
            <div class="flex w-2/6">
              <span class="flex flex-1 items-center"
                ><span class="line-score"
              /></span>
              <DoiText
                v-if="
                  action.scoreEquipe2 > action.scoreEquipe1 ||
                  action.scoreEquipe2 == action.scoreEquipe1
                "
                :textColor="0"
                :fontSize="4"
                >{{ action.scoreEquipe2 }}</DoiText
              >
              <DoiText v-else :textColor="1" :fontSize="4">{{
                action.scoreEquipe2
              }}</DoiText>
            </div>
          </div>
        </div>
        <div
          class="flex justify-center w-1/6"
          v-if="action.videos && action.videos.length > 0"
        >
          <div class="flex items-center">
            <div
              @click="
                $emit(
                  'setVideo',
                  action.videos[0].url,
                  action.videos[0].urlMiniature,
                  index
                )
              "
              class="cursor-pointer flex items-center justify-center w-8 h-8 ml-2 bg-fa-red"
            >
              <img src="@/assets/icons/common/play.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreVideoSetAction",
  data() {
    return {
      afficherAction: false,
    };
  },
  props: {
    tempsFortsActionSet: {},
    tempsForts: {},
    booking: {},
    thumbnail: "",
    video: "",
    index: 0,
  },
  methods: {
    getPosition(id_tf) {
      const sets = this.tempsForts.filter((tf) => tf.categorie === "set");
      return sets.findIndex((tf) => tf.id === id_tf);
    },
    setVideo(action, thumbnail) {
      this.video = action;
      this.thumbnail = thumbnail;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.line-score {
  height: 2px;
  width: 50%;
  background-color: $doi--color-color0;
  margin-right: auto;
  margin-left: auto;
}
</style>
