<template>
  <div class="relative">
    <Card
      :borderLeftColor="typeResa"
      @click="open = !open"
      class="p-4 flex-col lg:flex-row cursor-pointer border-card"
    >
      <template v-slot:center>
        <div class="flex justify-start flex-col">
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="5"
            :fontWeight="6"
            class="hidden sm:block uppercase mb-2 mr-3 text-left"
            >{{ info.intitule }}</DoiText
          >
          <DoiText
            :textColor="1"
            :fontFamily="1"
            :fontSize="3"
            :fontWeight="6"
            class="sm:hidden uppercase mb-2 mr-3 text-left"
            >{{ info.intitule }}</DoiText
          >
          <div class="flex flex-col items-start md:flex-row">
            <DoiText :fontFamily="0" :textColor="1" :fontSize="3">{{
              info.libelle
            }}</DoiText>
            <span class="flex justify-start items-center">
              <span class="md:ml-3 mr-3 w-3 h-3 bg-fa-red"></span>
              <DoiText :fontFamily="1" :textColor="1" :fontSize="3">{{
                info.libelle_fit_arena
              }}</DoiText>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <span class="material-icons red pr-2 pt-0 sm:pt-2">people</span>
        <DoiText :fontFamily="0" :textColor="3" class="pt-1 sm:pt-3">{{
          players
        }}</DoiText>
      </template>
    </Card>
    <div v-if="open" :class="open ? 'border-b border-black' : ''">
      <div v-if="info.type_feuille_match === 'point'">
        <div v-for="(match, index) in matchs" :key="index">
          <ScoreDetail :match="match" :index="index" :booking="info" />
        </div>
      </div>
      <div v-if="info.type_feuille_match === 'set'">
        <div v-for="(match, index) in matchs" :key="index">
          <ScoreDetailSet :match="match" :index="index" :booking="info" />
        </div>
      </div>
    </div>
    <div :class="open === false ? 'border-t border-black' : ''">
      <div
        v-if="
          matchs[0].equipes[0].equipiers[0] !== '' &&
          matchs[0].equipes[1].equipiers[0] !== ''
        "
        @click="open = !open"
        class="bg-black absolute right-0 px-5 py-2 flex items-center justify-between cursor-pointer w-full lg:w-4/12 lg:-bottom-10 border-button"
      >
        <DoiText :fontFamily="4" class="uppercase tracking-wider">{{
          open === false ? "Ouvrir la session" : "Fermer la session"
        }}</DoiText>
        <DoiText class="material-icons">{{
          open === false ? "keyboard_arrow_down" : "keyboard_arrow_up"
        }}</DoiText>
      </div>
      <div
        v-else
        class="bg-black absolute right-0 px-5 py-2 flex items-center justify-between cursor-pointer w-full lg:w-4/12 border-button"
      >
        <DoiText :fontFamily="4" class="uppercase tracking-wider"
          >Session non jouée</DoiText
        >
      </div>
    </div>
  </div>
</template>

<script>
import ScoreDetail from "./ScoreDetail";
import ScoreDetailSet from "./ScoreDetailSet";
import Card from "@/components/atomic/Card";

export default {
  name: "ScoreItem",
  data() {
    return {
      open: false,
    };
  },
  props: {
    info: Object,
    index: Number,
  },
  components: {
    ScoreDetail,
    ScoreDetailSet,
    Card,
  },
  computed: {
    participants() {
      return Object.values(this.$props.info.participants);
    },
    matchs() {
      return Object.values(this.info.parties);
    },
    players() {
      return this.participants.reduce(
        (acc, participant) => (participant.reponse == "2" ? acc + 1 : acc),
        0
      );
    },
    typeResa() {
      let style = 4;
      switch (this.info.type_resa) {
        case "grand_public":
          style = 0; // red
          break;
        case "gestionnaire_organisme":
        case "animateur":
          style = 1; // blue
          break;
      }
      return style; // grey (adhérent)
    },
  },
  watch: {
    index: {
      immediate: true,
      handler(value) {
        if (value === 0) this.open = true;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.border-card {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.red {
  color: $doi--color-color0;
}

.border-button {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
