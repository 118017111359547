<template>
  <div>
    <div class="flex justify-start md:justify-center w-full">
      <DoiHeader
        :level="3"
        :fontFamily="1"
        class="text-2xl md:text-4xl tracking-wide"
        >MES SCORES & REPLAYS</DoiHeader
      >
    </div>
    <div v-if="isLoading" class="text-center">
      <DoiSpinner :spinnerSize="1" />
    </div>
    <div v-else-if="scoreList.length > 0">
      <div v-for="(game, index) in scoreList" :key="index">
        <div v-if="game.parties.length > 0">
          <ScoreItem :info="game" :index="index" class="mt-4 mb-14" />
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center my-8">
      <DoiText class="text-center" :textColor="3"
        >Vous n'avez encore joué aucune partie.</DoiText
      >
    </div>
  </div>
</template>

<script>
import ScoreItem from "./ScoreItem";
import { getTempsFort } from "@/api/ScoreServices";
import dayjs from "dayjs";

export default {
  name: "ScoreList",
  data() {
    return {
      scoreList: [],
      isLoading: false,
    };
  },
  components: {
    ScoreItem,
  },
  mounted() {
    const contactId = this.$store.getters["UserModule/get_contact_id"];
    this.getScore(contactId);
  },
  computed: {
    getContactId() {
      return this.$store.getters["UserModule/get_contact_id"];
    },
  },
  methods: {
    async getScore(contactId) {
      const nTamponDebut = dayjs().unix() - 60 * 60 * 24 * 30;
      const nTamponFin = dayjs().unix();
      if (contactId !== null) {
        try {
          this.isLoading = true;
          const result = await getTempsFort({ nTamponDebut, nTamponFin });
          this.scoreList = Object.values(result.aReservations);
        } catch (err) {
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  watch: {
    getContactId(contactId) {
      this.getScore(contactId);
    },
  },
};
</script>
