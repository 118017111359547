<template>
  <div class="mt-28">
    <div class="flex justify-start md:justify-center w-full mb-6">
      <DoiHeader
        :level="4"
        :fontFamily="1"
        :headerColor="0"
        class="text-2xl md:text-4xl uppercase tracking-wide"
        >Historique des paiements</DoiHeader
      >
    </div>
    <div v-if="isLoaded">
      <DoiText
        v-if="paymentList.paiements.length === 0"
        :textColor="3"
        :fontSize="3"
        class="text-center mx-auto"
        >Vous n'avez effectué aucun paiement pour le moment.</DoiText
      >
      <PaymentHistoryList v-else :paymentList="paymentList.paiements" />
    </div>
    <DoiSpinner v-else />
  </div>
</template>

<script>
import PaymentHistoryList from "@/views/reglements/PaymentHistoryList";

import DoiSpinner from "@/components/atomic/DoiSpinner";

import { getHistoryPaymentV2 } from "@/api/PaymentService";

export default {
  name: "PaymentHistory",
  data() {
    return {
      isLoaded: false,
      paymentList: {},
    };
  },
  components: { DoiSpinner, PaymentHistoryList },
  async mounted() {
    this.setLoad(false);
    await this.getHistoryPayment();
    this.setLoad(true);
  },
  computed: {
    userId() {
      return this.$store.getters["AuthModule/get_userIdV2"];
    },
  },
  methods: {
    setLoad(item) {
      this.isLoaded = item;
    },
    async getHistoryPayment() {
      this.paymentList = await getHistoryPaymentV2(this.userId);
      // this.paymentList = {
      //   paiements: [
      //     {
      //       reservationId: 328,
      //       participantCount: 1,
      //       maxParticipantCount: 12,
      //       location: "Fit Arena Doing",
      //       orderDate: "2024-06-27 15:26:56",
      //       startDate: "2024-06-27 15:40:00",
      //       activity: "Football",
      //       totalAmount: 3700,
      //       fieldPrice: 3700,
      //       paymentShare: true,
      //       cardPan: "497010XXXXXX0055",
      //       payments: [
      //         { playerName: "loic", discountCodes: [], totalPaidAmount: 3700 },
      //       ],
      //     },
      //   ],
      // };
    },
  },
};
</script>
