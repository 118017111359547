<template>
  <div>
    <div v-if="!isLoaded" class="w-full flex justify-center">
      <DoiSpinner :spinnerSize="1" />
    </div>
    <div v-else-if="booking.type_feuille_match !== 'set'" class="w-full">
      <div class="flex lg:flex-row flex-col mb-8">
        <ScoreDetailTeamsItem
          class="lg:w-6/12 flex justify-start"
          v-for="(team, index) in equipes"
          :key="index"
          :team="team"
        />
      </div>
      <ScoreDetailNonPlayerItem
        class="lg:w-6/12 flex justify-start"
        v-for="(team, index) in equipes"
        :key="index"
        :team="team"
      />
    </div>
    <div v-else class="w-full">
      <div class="flex lg:flex-row flex-col mb-8">
        <ScoreDetailTeamsItemSet
          class="lg:w-6/12 flex justify-start"
          v-for="(team, index) in equipes"
          :key="index"
          :team="team"
        />
      </div>
      <ScoreDetailNonPlayerItemSet
        class="lg:w-6/12 flex justify-start"
        v-for="(team, index) in equipes"
        :key="index"
        :team="team"
      />
    </div>
  </div>
</template>

<script>
import ScoreDetailTeamsItem from "@/views/score/ScoreDetailTeamsItem";
import ScoreDetailNonPlayerItem from "@/views/score/ScoreDetailNonPlayerItem";
import ScoreDetailNonPlayerItemSet from "@/views/score/ScoreDetailNonPlayerItemSet";
import ScoreDetailTeamsItemSet from "@/views/score/ScoreDetailTeamsItemSet";
import { getInfoEquipe } from "@/api/ScoreServices";

export default {
  name: "ScoreDetailTeams",
  data() {
    return {
      equipes: [],
      isLoaded: false,
    };
  },
  components: {
    ScoreDetailTeamsItem,
    ScoreDetailTeamsItemSet,
    ScoreDetailNonPlayerItem,
    ScoreDetailNonPlayerItemSet,
  },
  props: {
    idPartie: String,
    booking: Object,
  },
  mounted() {
    (async () => {
      this.equipes = await getInfoEquipe(this.idPartie);
      delete this.equipes.total_equipe;
      if (Object.values(this.equipes).length > 2)
        delete this.equipes.non_joueur;
      this.equipes = Object.values(this.equipes);
      this.isLoaded = true;
    })();
  },
};
</script>
