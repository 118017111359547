<template>
  <div class="flex flex-col">
    <div class="flex flex-col lg:flex-row pb-3 border-b-2 mb-5">
      <DoiText
        :textColor="1"
        :fontSize="4"
        :fontFamily="1"
        class="uppercase mr-2"
        >{{ booking.intitule }}</DoiText
      >
      <DoiText :textColor="1" :fontSize="4" :fontFamily="0">{{
        booking.libelle
      }}</DoiText>
      <div class="flex">
        <span class="w-3 h-3 bg-fa-red block lg:mx-2 mr-2 my-3"></span>
        <DoiText :textColor="1" :fontSize="4" :fontFamily="1">{{
          booking.libelle_fit_arena
        }}</DoiText>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-col w-full lg:w-5/12 lg:pr-8">
        <div class="flex border-b-2 border-fa-red">
          <div class="flex items-center w-full pb-2 truncate">
            <DoiText
              :textColor="1"
              :fontSize="3"
              :fontFamily="1"
              class="uppercase team"
              >{{ match.equipes[0].libelle || "Équipe 1" }}</DoiText
            >
            <span class="flex flex-1 items-center"
              ><span class="line-score"
            /></span>
            <DoiText
              v-if="booking.type_feuille_match !== 'set'"
              :textColor="0"
              :fontSize="4"
              :fontFamily="1"
              >{{ score }}</DoiText
            >
            <DoiText v-else :textColor="0" :fontSize="4" :fontFamily="1">{{
              `${scoreGlobalSet.equipe_1} - ${scoreGlobalSet.equipe_2}`
            }}</DoiText>
            <span class="flex flex-1 items-center"
              ><span class="line-score"
            /></span>
            <DoiText
              :textColor="1"
              :fontSize="3"
              :fontFamily="1"
              class="uppercase team"
              >{{ match.equipes[1].libelle || "Équipe 2" }}</DoiText
            >
            <div
              v-if="this.match.videos.length > 0"
              @click="getFullVideo()"
              class="cursor-pointer flex items-center justify-center w-8 h-8 ml-2 bg-fa-red"
            >
              <img src="@/assets/icons/common/play.svg" />
            </div>
          </div>
        </div>
        <div class="flex flex-col" v-if="tempsForts.length === 0">
          <div class="flex mt-3 justify-around text-center">
            Aucun évènement dans cette partie
          </div>
        </div>
        <div class="flex flex-col border-b pb-4">
          <div v-for="(item, index) in tempsFortsActionSet" :key="index">
            <ScoreVideoSetAction
              @setVideo="setVideo"
              :tempsFortsActionSet="item"
              :tempsForts="tempsForts"
              :booking="booking"
              :thumbnail="thumbnail"
              :video="video"
              :index="index"
            />
          </div>
        </div>
      </div>
      <div class="w-full lg:w-6/12 mt-4 lg:mt-0">
        <div
          v-if="this.match.videos.length > 0"
          class="w-full flex items-start justify-center"
        >
          <video :poster="thumbnail" :key="video" controls autoplay muted>
            <source :src="video" type="video/mp4" codecs="avc1.4d002a" />
          </video>
          <div class="flex flex-col">
            <div
              class="hidden sm:flex cursor-pointer items-center share justify-center w-12 h-12 ml-2 mb-2 bg-red"
            >
              <ShareNetwork
                network="facebook"
                :url="`${video}`"
                :quote="`Replay - ${booking.intitule} ${booking.libelle} ${
                  numeroTempsFort > 0
                    ? '- ' +
                      tempsFortsActionSet[numeroTempsFort].categorie +
                      ' ' +
                      numeroTempsFort
                    : ''
                }`"
                title=""
                hashtags="FitArena"
                ><img
                  class="w-8"
                  src="@/assets/img/share/facebook.png"
                  alt="Facebook Fit Arena"
                />
              </ShareNetwork>
            </div>
            <div
              class="hidden sm:flex cursor-pointer items-center share justify-center w-12 h-12 ml-2 mt-2 bg-red"
            >
              <ShareNetwork
                network="twitter"
                :url="`${video}`"
                :title="`Replay - ${booking.intitule} ${booking.libelle} ${
                  numeroTempsFort > 0
                    ? '- ' +
                      tempsFortsActionSet[numeroTempsFort].categorie +
                      ' ' +
                      numeroTempsFort
                    : ''
                }`"
                hashtags="FitArena"
                ><img
                  class="w-8"
                  src="@/assets/img/share/twitter.png"
                  alt="Twitter Fit Arena"
                />
              </ShareNetwork>
            </div>
          </div>
        </div>
        <div v-else>
          <DoiText :textColor="1" :fontSize="3" class="mx-auto text-center"
            >Cette vidéo n'est pas encore disponible.</DoiText
          >
        </div>
        <div class="flex justify-center py-8 flex-col lg:flex-row items-center">
          <DoiText
            :fontFamily="5"
            :fontSize="3"
            :textColor="3"
            class="mb-2 lg:mb-0 lg:mr-8"
            >Choisissez une caméra</DoiText
          >
          <div class="btn w-auto lg:mr-4 mb-2 lg:mb-0">
            <span
              class="button"
              :class="nCamera === 0 ? 'red' : 'disabled'"
              @click="nCamera = 0"
            >
              <img class="mr-2" src="@/assets/icons/common/video-icon.svg" />
              CAMÉRA 1
            </span>
          </div>
          <div class="btn w-auto">
            <span
              class="button"
              :class="nCamera === 1 ? 'red' : 'disabled'"
              @click="nCamera = 1"
            >
              <img class="mr-2" src="@/assets/icons/common/video-icon.svg" />
              CAMÉRA 2
            </span>
          </div>
        </div>
        <div
          class="sm:hidden cursor-pointer mx-auto flex items-center justify-center w-10 h-10 bg-red share"
          @click="share(index, i)"
        >
          <img src="@/assets/icons/common/share.svg" width="20" height="20" />
        </div>
      </div>
    </div>
    <div class="flex flex-1 mt-2 justify-center">
      <ScoreDetailTeams
        v-if="match.id_match"
        :idPartie="match.id_match"
        :booking="booking"
      />
    </div>
  </div>
</template>

<script>
import ScoreDetailTeams from "@/views/score/ScoreDetailTeams";
import ScoreVideoSetAction from "@/views/score/ScoreVideoSetAction";
import Vue from "vue";

export default {
  name: "ScoreVideoSet",
  data() {
    return {
      list: [],
      nCamera: 0,
      video: "",
      sVideoUrl: '<source src="" type="video/mp4">',
      id_temps_fort: 0,
      thumbnail: "",
      scoreImg: "",
      infos: "",
      numeroTempsFort: 0,
      shareData: {
        title: "Partie Fit Arena",
        text: "Venez voir ma partie Fit Arena",
        errorMessage: "Navigateur non compatible",
      },
    };
  },
  props: {
    match: {},
    booking: {},
    score: {},
  },
  components: {
    ScoreDetailTeams,
    ScoreVideoSetAction,
  },
  computed: {
    tempsForts() {
      let temps_forts = this.match.temps_forts;
      if (this.booking.type_feuille_match !== "set") return temps_forts;
      else {
        return temps_forts.filter(
          (temps_fort) =>
            temps_fort.categorie === "set" || temps_fort.categorie === "action"
        );
      }
    },
    tempsFortsActionSet() {
      let temps_fort_format = [];
      let temps_forts_action = [];
      this.tempsForts.forEach((tf) => {
        if (tf.categorie === "set") {
          tf.action = temps_forts_action;
          temps_fort_format.push(tf);
          temps_forts_action = [];
        }
        if (tf.categorie === "action") temps_forts_action.push(tf);
      });
      return temps_fort_format;
    },
    scoreGlobalSet() {
      let score_global_set = {
        equipe_1: 0,
        equipe_2: 0,
      };
      let sets = this.tempsForts.filter((tf) => tf.categorie === "set");
      sets.forEach((score) => {
        if (score.scoreEquipe1 > score.scoreEquipe2)
          score_global_set.equipe_1 += 1;
        else if (score.scoreEquipe1 < score.scoreEquipe2)
          score_global_set.equipe_2 += 1;
      });
      return score_global_set;
    },
  },
  methods: {
    getFullVideo() {
      this.video = this.match.videos[0].url;
    },
    getVideoTempsFort(index) {
      this.video = this.match.temps_forts[index].videos[this.nCamera];
    },
    share(index, i) {
      if (!window.navigator.share) {
        Vue.$toast.error(this.shareData.errorMessage, { position: "top" });
      } else if (i) {
        const data = {
          title: this.shareData.title,
          text: this.shareData.text,
          url: `${this.tempsFortsActionSet[index].action[i].videos[0].url}`,
        };
        window.navigator.share(data);
      } else {
        const data = {
          title: this.shareData.title,
          text: this.shareData.text,
          url: `${this.tempsForts[index].videos[0].url}`,
        };
        window.navigator.share(data);
      }
    },
    setVideo(action, thumbnail, index) {
      this.numeroTempsFort = index + 1;
      this.video = action;
      this.thumbnail = thumbnail;
    },
    setMiniature(action) {
      return btoa(action);
    },
    setShareVideo(video) {
      return btoa(video);
    },
  },
  watch: {
    booking: {
      immediate: true,
      async handler() {
        if (this.match.videos.length > 0) {
          this.thumbnail = this.match.videos[0].urlMiniature;
        } else if (
          this.booking.id_sport === 2 ||
          this.booking.id_sport === 10
        ) {
          this.thumbnail =
            await require("@/assets/img/mock/video/thumbnail_video_basket.png");
        } else if (this.booking.id_sport === 4) {
          this.thumbnail =
            await require("@/assets/img/mock/video/thumbnail_video_bad.png");
        } else {
          this.thumbnail =
            await require("@/assets/img/mock/video/thumbnail_video.png");
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bg-red {
  background-color: $doi--color-color0;
}

video {
  width: 100%;
}

.btn {
  position: relative;
  min-width: 121px;
  min-height: 40px;
}

.line-score {
  height: 2px;
  width: 50%;
  background-color: $doi--color-color0;
  margin-right: auto;
  margin-left: auto;
}

.team {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90px;
}
</style>
