<template>
  <div class="w-full">
    <div class="flex justify-center w-full mb-5">
      <DoiHeader
        :level="3"
        :fontFamily="1"
        class="text-2xl md:text-4xl tracking-wide"
        >MES CLASSEMENTS</DoiHeader
      >
    </div>
    <div v-if="!isFetched" class="flex justify-center">
      <DoiSpinner :spinnerSize="1" />
    </div>
    <div v-else-if="ranking.length === 0" class="text-center mx-auto">
      <DoiText :textColor="3" :fontSize="4"
        >Vos classements ne seront accessibles qu’après votre première
        partie.</DoiText
      >
    </div>
    <div v-else>
      <RankingListItem
        class="mb-10"
        v-for="[sportId, rank] in Object.entries(ranking)"
        :key="sportId"
        :contact_id="contact_id"
        :sportId="sportId"
        :rank="rank"
        :open="openList.indexOf(sportId) !== -1"
      />
    </div>
  </div>
</template>

<script>
import RankingListItem from "@/views/ranking/RankingListItem";
import { getRanking } from "@/api/StatisticService";

export default {
  name: "RankingList",
  components: { RankingListItem },
  mounted() {
    this.getRanking();
  },
  data() {
    return {
      openList: [],
      ranking: [],
      isFetched: false,
    };
  },
  computed: {
    contact_id() {
      return this.$store.getters["UserModule/get_contact_id"];
    },
  },
  methods: {
    async getRanking() {
      this.ranking = await getRanking(this.contact_id, 1);
      this.isFetched = true;
    },
  },
};
</script>
