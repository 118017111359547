<template>
  <div class="mt-28">
    <div class="flex justify-start md:justify-center w-full mb-6">
      <DoiHeader
        :level="4"
        :fontFamily="1"
        :headerColor="0"
        class="text-2xl md:text-4xl uppercase tracking-wide"
        >Paiements à venir</DoiHeader
      >
    </div>
    <div v-if="isLoaded">
      <DoiText
        v-if="paymentList.paiements.length === 0"
        :textColor="3"
        :fontSize="3"
        class="text-center mx-auto"
        >Aucun paiement à venir pour le moment.</DoiText
      >
      <template v-else>
        <PaymentToComeList :paymentList="paymentList.paiements" />
      </template>
    </div>
    <DoiSpinner v-else />
  </div>
</template>

<script>
import PaymentToComeList from "@/views/reglements/PaymentToComeList";
import DoiSpinner from "@/components/atomic/DoiSpinner";
import { getPaymentsToCome } from "@/api/PaymentService";

export default {
  name: "PaymentToCome",
  data() {
    return {
      isLoaded: false,
      paymentList: {},
    };
  },
  components: { DoiSpinner, PaymentToComeList },
  async mounted() {
    this.setLoad(false);
    await this.getPayments();
    this.setLoad(true);
  },
  computed: {
    userId() {
      return this.$store.getters["AuthModule/get_userIdV2"];
    },
  },
  methods: {
    setLoad(item) {
      this.isLoaded = item;
    },
    async getPayments() {
      this.paymentList = await getPaymentsToCome(this.userId);
    },
  },
};
</script>
