<template>
  <div>
    <div v-for="(payment, index) in paymentList" :key="payment.reservationId">
      <PaymentToBeSettledListItem
        :isOdd="index % 2 ? true : false"
        :payment="payment"
        class="mt-4 mb-14"
      />
    </div>
  </div>
</template>

<script>
import PaymentToBeSettledListItem from "@/views/reglements/PaymentToBeSettledListItem";

export default {
  name: "PaymentToBeSettledList",
  components: { PaymentToBeSettledListItem },
  props: {
    paymentList: Array,
  },
};
</script>
