<template>
  <Modal
    title="SUPPRESSION DU PROFIL"
    :open="open"
    headerColor="red"
    :headerIcon="require('@/assets/icons/utils/compte/person.svg')"
    size="s"
    :keepratio="true"
    @close-modal="close"
  >
    <template slot="modal_content">
      <DoiText
        :textColor="1"
        :fontFamily="5"
        :fontSize="3"
        class="text-center mx-auto"
        >Vous êtes sur le point de supprimer votre compte, voulez-vous continuer
        ?</DoiText
      >
      <DoiText
        :textColor="1"
        :fontFamily="5"
        :fontSize="2"
        class="text-center mx-auto"
        >Cette action est irréversible et entraînera la suppression de vos
        données.</DoiText
      >
    </template>

    <template slot="modal_footer">
      <div class="flex p-8 justify-center">
        <DoiButton
          @click="close"
          class="w-6/12 mr-4 tracking-widest"
          :fontFamily="6"
          :bgColor="2"
          :bgHover="3"
          >ANNULER</DoiButton
        >
        <button @click="submit" class="button red w-6/12" type="submit">
          <DoiText :fontFamily="6" class="tracking-widest">ENREGISTRER</DoiText>
          <DoiSpinner :show="spinner" :color="1" class="ml-3" />
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/molecules/Modal";

import { removeUser } from "@/api/UserService";

export default {
  name: "ProfilRemove",
  data() {
    return {
      spinner: false,
    };
  },
  components: { Modal },
  props: {
    userId: Number,
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async submit() {
      this.spinner = true;
      await removeUser(this.userId);
      this.$store.dispatch("AuthModule/DECONNEXION");
      this.close();
      this.spinner = false;
    },
  },
};
</script>
