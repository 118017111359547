<template>
  <div class="planning-reservation">
    <div class="flex justify-center w-full mb-5">
      <DoiHeader
        :level="3"
        :fontFamily="1"
        class="text-2xl md:text-4xl tracking-wide"
        >MON PLANNING</DoiHeader
      >
    </div>
    <div class="flex flex-col lg:flex-row justify-between items-center mb-4">
      <div class="flex flex-col md:flex-row items-center">
        <BookingSearch
          :filterList="filterTypeResa"
          @updateFilter="updateFilter"
        />
      </div>
    </div>
    <table class="table-fixed w-full">
      <div v-show="!isRetrieve" class="text-center">
        <DoiSpinner :spinnerSize="1" />
      </div>
      <div v-if="isRetrieve">
        <template v-if="isDesktop" class="container mx-auto max-w-6xl py-8">
          <FullCalendarAsync
            @eventDidMount="handleEvents"
            @eventMouseEnter="handleMouseEnter"
            @eventMouseLeave="handleMouseLeave"
            @dayHeaderDidMount="handleDayHeader"
            @eventClick="handleSelect"
            :events="slotsDataFiltered"
          />
        </template>
        <template v-else>
          <PlanningLightReservation
            @onReload="getSlots()"
            :reservations="slotsDataFiltered"
          />
        </template>
        <div v-if="events.length === 0" class="flex justify-center mt-4">
          <DoiText :fontFamily="0" :textColor="0" :fontSize="4"
            >AUCUN CRÉNEAU RÉSERVÉ</DoiText
          >
        </div>
      </div>
    </table>
  </div>
</template>

<script>
import DoiSpinner from "@/components/atomic/DoiSpinner";

import BookingSearch from "@/views/booking/BookingSearch";
import PlanningLightReservation from "@/views/planning/PlanningLightReservation";

import { getReservationGroupe } from "@/api/ScoreServices";

import dayjs from "dayjs";

export default {
  name: "PlanningReservation",
  components: {
    FullCalendarAsync: () => ({
      // Le composant à charger (doit être une `Promise`)
      component: import(
        /* webpackChunkName: "calendar" */ "@/components/organisms/FullCalendarAsync"
      ),
      // Un composant à utiliser pendant que le composant asynchrone se charge
      loading: {
        render: (h) => h(DoiSpinner, { props: { spinnerSize: "1" } }),
      },
      // Un composant d'erreur à utiliser au cas où le chargement échoue
      error: { render: (h) => h(DoiSpinner, { props: { spinnerSize: "1" } }) },
      // Le délai à patienter avant d'afficher le composant de chargement. Par défaut : 200ms.
      delay: 200,
      // Le composant d'erreur sera affiché si un délai de timeout est fourni et dépassé.
      // Par défaut: délai infini.
      timeout: 10000,
    }),
    PlanningLightReservation,
    BookingSearch,
  },
  data() {
    return {
      filterTypeResa: [],
      events: [],
      isDesktop: window.innerWidth >= 780,
      slotsData: [],
      isRetrieve: false,
      intervalCalendar: {
        currentStart: null,
        currentEnd: null,
      },
    };
  },
  mounted() {
    this.getSlots();
  },
  computed: {
    getIdSport() {
      return this.$store.getters["FitArenaModule/get_id_sport"];
    },
    slotsDataFiltered() {
      return this.slotsData.filter((bookingItem) => {
        return this.filterTypeResa.indexOf(bookingItem.type_resa) === -1;
      });
    },
  },
  methods: {
    updateFilter(filterList) {
      this.filterTypeResa = filterList;
      this.filterTypeResa.splice(this.filterTypeResa.length);
    },
    async getSlots(dateDebut = dayjs(), dateFin = dayjs()) {
      const nTamponDebut = dayjs(dateDebut).startOf("day").unix();
      const nTamponFin = dayjs(dateFin).endOf("day").unix() + 60 * 60 * 24 * 30;

      const result = await getReservationGroupe({ nTamponDebut, nTamponFin });

      let slotsData = [];
      if (result.aReservations) {
        this.events.length = 0;
        this.scoreList = Object.values(result.aReservations).reverse();

        this.scoreList.forEach((item, i) => {
          const calendarObject = {
            id: item.id_reservation,
            title: item.intitule,
            start: dayjs(item.tampon_debut * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end: dayjs(item.tampon_fin * 1000).format("YYYY-MM-DD HH:mm:ss"),
            price: (item.tarif_par_personne * item.place_max) / 1000,
            status: "disponible",
            nIdCreneau: item.id_reservation,
            nIdContact: 0,
            nIdSport: this.getIdSport,
            selected: false,
            type_resa: item.type_resa,
            place_max: item.place_max,
            participants: item.participants,
          };
          this.events.push(calendarObject);
          slotsData = [...slotsData, calendarObject];
        });

        this.slotsData = slotsData;
        this.isRetrieve = true;

        const element = document.getElementsByClassName("fc-timegrid-col");
        const currentDate = dayjs().format("YYYY-MM-DD");
        const currentDateObj = dayjs(currentDate);

        element.forEach((element) => {
          const attr = element.getAttribute("data-date");
          const planningDate = dayjs(attr).format("YYYY-MM-DD");
          const planningDateObj = dayjs(new Date(planningDate));

          if (isNaN(currentDateObj.diff(planningDateObj)) === false) {
            if (planningDateObj.diff(currentDateObj) < 0) {
              element
                .getElementsByClassName("fc-timegrid-col-frame")[0]
                .classList.add("bg-fa-middle-gray");
            }
          }
        });
      }
    },
    handleDayHeader(info) {
      this.intervalCalendar = Object.assign({}, this.intervalCalendar, {
        currentStart: new Date(info.view.currentStart),
        currentEnd: new Date(info.view.currentEnd),
      });

      var date = new Date(info.date);
      var day = document.createElement("div");
      day.classList.add("text-3xl", "date-field", "font-Raleway-Bold");
      day.appendChild(document.createTextNode(date.getDate()));
      info.el.children[0].children[0].appendChild(day);
    },
    handleEvents(arg) {
      arg.el.style.borderColor = "transparent";
      arg.el.style.borderRadius = "0px";
      var element = document.createElement("div");
      element.classList.add("text-xs", "price-field", "font-Raleway-Light");

      if (arg.event.extendedProps.status === "disponible") {
        if (this.events[0].type_resa == "animateur") {
          arg.el.style.backgroundColor = "#419ae8";
        } else {
          arg.el.style.backgroundColor = "#DE001A";
        }
      } else if (arg.event.extendedProps.status === "reserve") {
        arg.el.style.backgroundColor = "#fff";
        arg.el.classList.add("event-reserve");
      } else if (arg.event.extendedProps.status === "finalisee") {
        arg.el.style.backgroundColor = "#DE001A";
      }

      var date = document.createElement("div");
      date.classList.add(
        "text-xs",
        "date-field",
        "font-Raleway-Light",
        "hidden"
      );

      if (arg.event.extendedProps.status === "disponible") {
        const start = dayjs(arg.event.start).format("HH[h]mm");
        const end = dayjs(arg.event.end).format("HH[h]mm");
        date.appendChild(document.createTextNode(`de ${start} à ${end}`));
      }

      if (arg.el.children[0].children.length > 0) {
        arg.el.children[0].children[0].appendChild(element);
        arg.el.children[0].children[0].appendChild(date);
      }

      if (arg.event.extendedProps.selected) {
        arg.el.classList.add("selected");
        const priceField = info.el.getElementsByClassName("price-field");
        const dateField = info.el.getElementsByClassName("date-field");
        if (priceField.length > 0) priceField[0].classList.add("hidden");
        if (dateField.length > 0) dateField[0].classList.remove("hidden");
      }
    },
  },
  watch: {
    intervalCalendar(value) {
      this.getSlots(value.currentStart, value.currentEnd);
    },
  },
};
</script>
