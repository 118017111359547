<template>
  <div>
    <div
      @click="_selectContact()"
      :class="selected && disableAction ? 'border-fa-red selected' : ''"
      class="h-80 flex w-full flex-col rounded border-2 border-gray-100 relative transition transition-300"
    >
      <Modal
        title="SUPPRESSION"
        :open="showConfirmation"
        size="xs"
        type="question"
        :keepratio="true"
        :displayHeader="true"
        @close-modal="showConfirmation = false"
      >
        <template slot="modal_content">
          <DoiText
            :textColor="1"
            :fontFamily="5"
            :fontSize="3"
            class="p-4 overflow-hidden text-center"
            >Vous êtes sur le point de supprimer un membre de votre famille de
            votre liste, voulez-vous continuer ?</DoiText
          >
        </template>
        <template slot="modal_footer">
          <div class="flex p-4 items-center justify-center">
            <DoiButton
              @click="showConfirmation = false"
              :bgColor="2"
              :bgHover="3"
              class="lg:mr-4 lg:mb-0"
              >NON</DoiButton
            >
            <DoiButton @click="_deleteContact()" :bgHover="1" class="ml-4"
              >OUI</DoiButton
            >
          </div>
        </template>
      </Modal>

      <div
        class="relative flex flex-col items-center justify-center w-full p-4 max-w-2k"
        :class="disableAction ? 'cursor-pointer' : ''"
      >
        <div class="flex items-center rounded-full border mb-2 overflow-hidden">
          <DoiImage size="5rem" rounded :src="contactAvatar" />
        </div>
        <div class="flex flex-col text-center truncate">
          <DoiText
            :textColor="3"
            :fontSize="4"
            class="text-ellipsis overflow-hidden"
          >
            {{ contactData.prenom }}<br />
            {{ contactData.nom }}
          </DoiText>
          <DoiText :textColor="0" :fontSize="3" :fontFamily="4">{{
            contactData.identifiant
          }}</DoiText>
          <DoiText italic :textColor="1" :fontFamily="4" class="mt-2">
            {{ contactData.mineur === "1" ? "Contact mineur" : null }}
          </DoiText>
        </div>
        <div v-if="disableAction === false" class="flex mt-4">
          <div
            @click="_getUserData()"
            class="transform bg-transparent cursor-pointer duration-200 mr-6"
          >
            <img src="@/assets/icons/utils/compte/edit.svg" />
          </div>
          <div
            @click="showConfirmation = !showConfirmation"
            class="transform bg-transparent cursor-pointer duration-200"
          >
            <img src="@/assets/icons/utils/compte/trash.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import { deleteContactV2, getContactV2 } from "@/api/ContactService";
import { generateImageUrlV2 } from "@/utils/imgUrlBuilder";

export default {
  name: "ContactCard",
  data() {
    return {
      showConfirmation: false,
      selected: false,
    };
  },
  components: { Modal },
  props: {
    contactData: Object,
    disableAction: {
      type: Boolean,
      default: false,
    },
    selectContact: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$props.selectContact !== undefined && this.$props.selectContact)
      this._selectContact();
  },
  computed: {
    contactAvatar() {
      return this.contactData.url !== "" && this.contactData.url !== undefined
        ? generateImageUrlV2(this.contactData.url)
        : require("@/assets/img/no-avatar.png");
    },
  },
  methods: {
    _selectContact() {
      this.$emit("onSelect", this.$props.contactData.id);
      this.selected = !this.selected;
    },
    async _deleteContact() {
      const result = await deleteContactV2(this.$props.contactData.id);

      if (result !== undefined && result !== null) {
        if (result.bSucces) {
          const message = "Le contact a bien été supprimé";
          if (screen.width > 640) {
            this.$toast.success(message);
          } else {
            this.$toast.success(message, {
              position: "top",
            });
          }
        }
      }
      this.$emit("onRefresh");
      this.showConfirmation = false;
    },
    async _getUserData() {
      const response = await getContactV2(this.$props.contactData.id);

      if (response !== undefined && response !== null)
        this.$emit("onEdit", response);
      this.loading = false;
    },
  },
  watch: {
    selectContact(val) {
      this._selectContact();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.selected:before {
  content: "";
  width: 35px;
  height: 35px;
  background-color: $doi--color-color0;
  position: absolute;
  top: 0;
  z-index: 10;
}

.selected:after {
  content: "";
  left: 12px;
  top: 3px;
  width: 10px;
  height: 20px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  z-index: 1000;
}
</style>
