<template>
  <div class="p-4">
    <ProfilEdit
      v-if="openProfile"
      @close="openProfile = false"
      :userInfo="get_user"
      :open="openProfile"
    />
    <ProfilTelEdit
      v-if="openTel"
      @close="openTel = false"
      :userInfo="get_user"
      :open="openTel"
    />
    <ProfilRemove
      v-if="removeUserModal"
      @close="removeUserModal = false"
      :userId="get_userIdV2"
      :open="removeUserModal"
    />
    <div class="bg-profil mt-16">
      <div v-if="pending" class="flex items-center justify-center p-8">
        <DoiSpinner :show="pending" />
      </div>
      <div v-else>
        <div class="relative flex flex-col items-center justify-center">
          <DoiImage
            class="-mt-16"
            size="9rem"
            :rounded="true"
            :src="profilAvatar"
          />
        </div>
        <div class="relative flex flex-col items-center justify-center">
          <div class="space-y-4 mt-2">
            <span
              class="flex items-center cursor-pointer"
              @click="openProfile = true"
            >
              <DoiText
                :textColor="3"
                :fontFamily="5"
                class="underline text-center"
                >Éditer mon profil</DoiText
              >
              <img
                src="@/assets/icons/common/edit.svg"
                class="material-icons text-fa-dark-gray flex items-center ml-2"
              />
            </span>
            <div @click="removeUserModal = true">
              <DoiText
                :textColor="3"
                :fontFamily="5"
                class="underline text-center cursor-pointer"
                >Supprimer mon compte</DoiText
              >
            </div>
          </div>
        </div>
        <div class="text-center flex flex-col mt-4">
          <DoiText
            :textColor="3"
            :fontSize="4"
            :fontFamily="1"
            class="name mx-auto w-10/12"
            >{{ get_user.prenom }}
            <DoiText
              type="span"
              :textColor="3"
              :fontSize="4"
              :fontFamily="1"
              class="uppercase"
              >{{ get_user.nom }}</DoiText
            ></DoiText
          >
          <DoiText
            :textColor="0"
            :fontFamily="2"
            :fontSize="3"
            class="pseudo mx-auto w-10/12"
            >{{ get_user.identifiant }}</DoiText
          >
        </div>
        <div class="text-center flex flex-col mt-4">
          <DoiText
            :textColor="3"
            :fontSize="3"
            :fontFamily="5"
            class="email mx-auto w-10/12"
            >{{ get_user.userMail }}</DoiText
          >
        </div>
        <div class="relative flex flex-col items-center justify-center">
          <span class="flex cursor-pointer" @click="openTel = true">
            <DoiText :textColor="3" :fontSize="3" :fontFamily="0">{{
              get_user.portable
            }}</DoiText>
            <img
              v-if="get_user.portable !== '' && get_user.portable !== null"
              src="@/assets/icons/common/edit.svg"
              class="material-icons text-fa-dark-gray flex items-center ml-2"
            />
          </span>
        </div>
        <div class="p-4">
          <div>
            <!-- <DoiButton
              link="/profile?q=planning"
              class="mb-5"
              :fontFamily="5"
              :bgHover="1"
              >MON PLANNING</DoiButton
            > -->
            <DoiButton
              link="/profile?q=contacts"
              :bgColor="2"
              :bgHover="3"
              :fontFamily="5"
              >MA FAMILLE</DoiButton
            >
            <DoiButton
              link="profile?q=video"
              class="mt-2"
              :bgColor="2"
              :bgHover="3"
              :fontFamily="5"
              >MES SCORES & REPLAYS</DoiButton
            >
            <DoiButton
              link="profile?q=reglements"
              class="mt-2"
              :bgColor="2"
              :bgHover="3"
              :fontFamily="5"
              >MES PAIEMENTS</DoiButton
            >
            <DoiButton
              link="profile?q=reservation"
              class="mt-5"
              :bgColor="5"
              :fontFamily="5"
              >MES RÉSERVATIONS</DoiButton
            >
          </div>
          <div
            class="text-center cursor-pointer mt-4 underline"
            @click="vRemoveToken()"
          >
            <DoiText :textColor="3" :fontSize="2" :fontFamily="6"
              >Déconnexion</DoiText
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilEdit from "@/views/user/ProfilEdit";
import ProfilTelEdit from "@/views/user/ProfilTelEdit";
import ProfilRemove from "@/views/user/ProfilRemove";

import { mapGetters } from "vuex";
import { generateImageUrl } from "@/utils/imgUrlBuilder";

export default {
  data() {
    return {
      openProfile: false,
      removeUserModal: false,
      openTel: false,
    };
  },
  components: { ProfilEdit, ProfilTelEdit, ProfilRemove },
  created() {
    window.addEventListener("resize", this.reportWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.reportWindowSize);
  },
  computed: {
    ...mapGetters("UserModule", ["get_user", "user_fetched"]),
    ...mapGetters("AuthModule", ["get_userIdV2"]),
    profilAvatar() {
      return this.get_user.avatar === "" || !this.get_user.avatar
        ? require("@/assets/img/no-avatar.png")
        : generateImageUrl(this.get_user.avatar);
    },
    pending() {
      return !this.user_fetched;
    },
  },
  methods: {
    vRemoveToken() {
      this.$store.dispatch("AuthModule/DECONNEXION");
    },
  },
};
</script>

<style>
.email,
.name,
.pseudo {
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  height: 1px;
}

.avatar-overlay {
  width: 130px;
  height: 130px;
}

.img-container {
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
}

.bg-profil {
  background-color: rgba(242, 242, 242, 1);
  border-radius: 4px;
}

.portrait {
  float: left;
  width: 175px;
  height: 175px;
  overflow: hidden;
}

.portrait-img {
  width: 175px;
  height: auto;
}

.landscape {
  float: left;
  width: 175px;
  height: 175px;
  overflow: hidden;
}

.landscape img {
  width: auto;
  height: 175px;
}
</style>
