<template>
  <div>
    <div class="flex justify-start md:justify-center w-full mb-6">
      <DoiHeader
        :level="4"
        :fontFamily="1"
        :headerColor="0"
        class="text-2xl md:text-4xl uppercase tracking-wide"
        >Paiements à régulariser</DoiHeader
      >
    </div>
    <div v-if="isLoaded">
      <DoiText
        v-if="paymentList.paiements.length === 0"
        :textColor="3"
        :fontSize="3"
        class="text-center mx-auto"
        >Aucun paiement à régulariser pour le moment.</DoiText
      >
      <template v-else>
        <PaymentToBeSettledList :paymentList="paymentList.paiements" />
      </template>
    </div>
    <DoiSpinner v-else />
  </div>
</template>

<script>
import PaymentToBeSettledList from "@/views/reglements/PaymentToBeSettledList";
import DoiSpinner from "@/components/atomic/DoiSpinner";
import { getPaymentsToBeSettled } from "@/api/PaymentService";

export default {
  name: "PaymentToBeSettled",
  data() {
    return {
      isLoaded: false,
      paymentList: {},
    };
  },
  components: { DoiSpinner, PaymentToBeSettledList },
  async mounted() {
    this.setLoad(false);
    await this.getPayments();
    this.setLoad(true);
  },
  computed: {
    userId() {
      return this.$store.getters["AuthModule/get_userIdV2"];
    },
  },
  methods: {
    setLoad(item) {
      this.isLoaded = item;
    },
    async getPayments() {
      this.paymentList = await getPaymentsToBeSettled(this.userId);
      // this.paymentList = {
      //   paiements: [
      //     {
      //       reservationId: 328,
      //       participantCount: 1,
      //       maxParticipantCount: 12,
      //       location: "Fit Arena Doing",
      //       orderDate: "2024-06-27 15:26:56",
      //       startDate: "2024-06-27 15:40:00",
      //       activity: "Football",
      //       totalAmount: 3700,
      //       fieldPrice: 3700,
      //       paymentShare: true,
      //       cardPan: "497010XXXXXX0055",
      //       payments: [
      //         {
      //           playerName: "loic",
      //           discountCodes: [],
      //           totalPaidAmount: 3700,
      //           paiementStatus: "contentious",
      //           paiementId: 234546,
      //           paiementOrderUrl: "https:\/\/secure.payzen.eu\/t\/7gawu4sx",
      //           dateExpirationOrderPayment: "2024-08-13T16:03:14+02:00",
      //         },
      //         {
      //           playerName: "julie",
      //           discountCodes: [],
      //           totalPaidAmount: 3700,
      //           paiementStatus: "create",
      //           paiementId: 234546,
      //           paiementOrderUrl: "",
      //         },
      //       ],
      //     },
      //   ],
      // };
    },
  },
};
</script>
