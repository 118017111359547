<template>
  <div>
    <div v-for="(payment, index) in paymentList" :key="payment.reservationId">
      <PaymentToComeListItem
        :isOdd="index % 2 ? true : false"
        :payment="payment"
        class="mt-4 mb-14"
      />
    </div>
  </div>
</template>

<script>
import PaymentToComeListItem from "@/views/reglements/PaymentToComeListItem";

export default {
  name: "PaymentToComeList",
  components: { PaymentToComeListItem },
  props: {
    paymentList: Array,
  },
};
</script>
