<template>
  <div
    v-if="
      match.equipes[0].equipiers[0] !== '' &&
      match.equipes[1].equipiers[0] !== ''
    "
  >
    <div class="border-b-2 bd-gray mt-4 pb-4 p-4">
      <DoiText
        :textColor="1"
        :fontFamily="1"
        :fontSize="5"
        class="text-center mb-4"
        >MATCH {{ index + 1 }}</DoiText
      >
      <div class="flex w-full">
        <div class="flex-auto">
          <div class="flex pb-1 mb-5">
            <DoiText
              :textColor="1"
              class="ml-2"
              :fontFamily="1"
              :fontSize="3"
              >{{ match.libelle.split(" ")[3] }}</DoiText
            >
          </div>
        </div>
        <div class="flex justify-end">
          <div class="flex-auto">
            <div class="border-b-2 border-black pb-1 inline-flex items-center">
              <img class="w-6 h-6 mr" src="@/assets/icons/common/chrono.svg" />
              <DoiText
                class="ml-2 duration"
                :textColor="1"
                :fontSize="4"
                :fontFamily="0"
                >{{ duration }} min</DoiText
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-between items-center mb-4">
        <DoiText
          v-if="match.equipes.length !== 0"
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase w-1/3 break-all"
          >{{ match.equipes[0].libelle }}</DoiText
        >
        <DoiText
          v-else
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase w-1/3"
          >ÉQUIPE 1</DoiText
        >
        <DoiText
          :fontFamily="1"
          :fontSize="7"
          :textColor="0"
          class="score flex justify-center w-1/3"
          >{{ scoreGlobal }}</DoiText
        >
        <DoiText
          v-if="match.equipes.length !== 0"
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase break-all w-1/3 flex justify-end"
          >{{ match.equipes[1].libelle }}</DoiText
        >
        <DoiText
          v-else
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase w-1/3 flex justify-end"
          >ÉQUIPE 2</DoiText
        >
      </div>
      <div class="mb-6">
        <div class="flex w-full mb-2" v-for="(set, index) in sets" :key="index">
          <div
            class="flex justify-between border border-gray rounded-md px-3 py-1 w-full mr-1"
          >
            <DoiText :textColor="1" :fontFamily="0" :fontSize="3">{{
              set.score.split(":")[0]
            }}</DoiText>
          </div>
          <DoiText
            :textColor="3"
            :fontFamily="6"
            :fontSize="3"
            class="set-label flex items-center justify-center w-96 text-center"
            >SET {{ index + 1 }}</DoiText
          >
          <div
            class="flex justify-between border border-gray rounded-md px-3 py-1 w-full ml-1"
          >
            <DoiText :textColor="1" :fontFamily="0" :fontSize="3">{{
              set.score.split(":")[1]
            }}</DoiText>
          </div>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row justify-end items-center">
        <button @click="openScoreModal = true" class="red py-1.5 px-2.5">
          <img src="@/assets/icons/common/video-icon.svg" />
          <DoiText :fontFamily="1" :fontSize="3">VOIR REPLAYS</DoiText>
        </button>
      </div>
      <Modal
        id="replays"
        :title="'REPLAYS'"
        :open="openScoreModal"
        size="md"
        headerColor="red"
        :keepratio="true"
        @close-modal="openScoreModal = false"
      >
        <template slot="modal_content">
          <div>
            <ScoreVideoSet
              :match="match"
              :score="scoreGlobal"
              :booking="booking"
            />
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/molecules/Modal";
import ScoreVideoSet from "./ScoreVideoSet";

export default {
  name: "ScoreDetailSet",
  data() {
    return {
      loading: false,
      scoreDetail: {},
      openScoreModal: false,
      open: false,
    };
  },
  props: {
    match: Object,
    booking: Object,
    index: Number,
  },
  components: {
    Modal,
    ScoreVideoSet,
  },
  computed: {
    duration() {
      return Math.trunc((this.match.fin - this.match.debut) / 60);
    },
    periodes() {
      return Object.values(this.match.periodes);
    },
    sets() {
      const sets = Object.values(this.match.temps_forts);
      return sets.filter((tf) => tf.categorie === "set");
    },
    scoreEquipeOne() {
      let scoreEquipeOne = 0;

      this.sets.forEach((tf) => {
        if (tf.score.split(":")[0] > tf.score.split(":")[1]) scoreEquipeOne++;
      });
      return scoreEquipeOne;
    },
    scoreEquipeTwo() {
      let scoreEquipeTwo = 0;
      this.sets.forEach((tf) => {
        if (tf.score.split(":")[0] < tf.score.split(":")[1]) scoreEquipeTwo++;
      });
      return scoreEquipeTwo;
    },
    scoreGlobal() {
      return `${this.scoreEquipeOne} - ${this.scoreEquipeTwo}`;
    },
  },
  methods: {
    count() {
      return Object.keys(this.booking.parties).length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.set-label {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
}
.set-label::before {
  content: "";
  width: 20px;
  background: $doi--color-color4;
  height: 1px;
}
.set-label::after {
  content: "";
  width: 20px;
  background: $doi--color-color4;
  height: 1px;
}

.border-gray {
  border: 1px solid $doi--color-color4;
}

.bd-gray {
  color: $doi--color-color4;
}

.score {
  min-width: 130px;
}

@media screen and (max-width: 600px) {
  .duration {
    font-size: $doi--text-font-size3;
  }
}
</style>
