<template>
  <div
    v-if="
      match.equipes[0].equipiers[0] !== '' &&
      match.equipes[1].equipiers[0] !== ''
    "
  >
    <div class="border-b-2 bd-gray mt-4 pb-4 p-4">
      <DoiText
        :textColor="1"
        :fontFamily="1"
        :fontSize="5"
        class="text-center mb-4"
        >MATCH {{ index + 1 }}</DoiText
      >
      <div class="flex w-full">
        <div class="flex-auto">
          <div class="flex pb-1 mb-5">
            <DoiText
              :textColor="1"
              class="ml-2"
              :fontFamily="1"
              :fontSize="3"
              >{{ match.libelle.split(" ")[3] }}</DoiText
            >
          </div>
        </div>
        <div class="flex justify-end">
          <div class="flex-auto">
            <div
              class="border-b-2 border-black pb-1 mb-5 inline-flex items-center"
            >
              <img class="w-6 h-6 mr" src="@/assets/icons/common/chrono.svg" />
              <DoiText
                class="ml-2 duration"
                :textColor="1"
                :fontSize="4"
                :fontFamily="0"
                >{{ duration }} min</DoiText
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex w-full justify-between items-center sm:items-baseline mb-4"
      >
        <DoiText
          v-if="match.equipes.length !== 0"
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase w-1/3 break-all"
          >{{ match.equipes[0].libelle }}</DoiText
        >
        <DoiText
          v-else
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase w-1/3"
          >ÉQUIPE 1</DoiText
        >
        <DoiText
          :fontFamily="1"
          :fontSize="6"
          :textColor="0"
          class="score flex justify-center w-1/3"
          >{{ scoreGlobal }}</DoiText
        >
        <DoiText
          v-if="match.equipes.length !== 0"
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase w-1/3 break-all flex justify-end"
          >{{ match.equipes[1].libelle }}</DoiText
        >
        <DoiText
          v-else
          :fontFamily="1"
          :textColor="1"
          :fontSize="3"
          class="uppercase w-1/3 flex justify-end"
          >ÉQUIPE 2</DoiText
        >
      </div>
      <div class="flex">
        <div class="flex flex-1 flex-col mr-2">
          <div
            class="flex justify-between border border-gray-400 rounded-md px-3 py-1 w-full mr-1 mb-4"
            v-for="(item, index) in teamOne"
            :key="index"
          >
            <DoiText type="span" :textColor="3">{{ item.sNomJoueur }}</DoiText>
            <DoiText type="span" :textColor="3">{{
              item.nScoreJoueur
            }}</DoiText>
          </div>
        </div>
        <div class="flex flex-1 flex-col ml-2">
          <div
            class="flex justify-between border border-gray-400 rounded-md px-3 py-1 w-full mr-1 mb-4"
            v-for="(item, index) in teamTwo"
            :key="index"
          >
            <DoiText type="span" :textColor="3">{{ item.sNomJoueur }}</DoiText>
            <DoiText type="span" :textColor="3">{{
              item.nScoreJoueur
            }}</DoiText>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <DoiText
          v-if="booking.intitule === 'Futsal'"
          :textColor="1"
          :fontFamily="0"
          :fontSize="3"
          >Nombre de buts marqués :
          <DoiText type="span" :textColor="1" :fontFamily="1" :fontSize="3">{{
            point
          }}</DoiText></DoiText
        >
        <DoiText
          v-else-if="
            booking.intitule === 'Basket 3x3' ||
            booking.intitule === 'Basket 5x5'
          "
          :textColor="1"
          :fontFamily="0"
          :fontSize="3"
          >Nombre de paniers marqués :
          <DoiText type="span" :textColor="1" :fontFamily="1" :fontSize="3">{{
            point
          }}</DoiText></DoiText
        >
        <DoiText v-else :textColor="1" :fontFamily="0" :fontSize="3"
          >Nombre de points marqués :
          <DoiText type="span" :textColor="1" :fontFamily="1" :fontSize="3">{{
            point
          }}</DoiText></DoiText
        >
        <DoiText
          :textColor="1"
          :fontFamily="0"
          :fontSize="3"
          v-if="
            booking.intitule === 'Basket 3x3' ||
            booking.intitule === 'Basket 5x5' ||
            booking.intitule === 'Futsal'
          "
          >Nombre de fautes :
          <DoiText type="span" :textColor="1" :fontFamily="1" :fontSize="3">{{
            faute
          }}</DoiText></DoiText
        >
      </div>
      <div class="flex flex-col lg:flex-row justify-end items-center">
        <button
          @click="openScoreModal = true"
          class="button red icon py-1.5 px-2.5 mt-2"
        >
          <img src="@/assets/icons/common/video-icon.svg" />
          <DoiText :fontFamily="1" :fontSize="3">VOIR REPLAYS</DoiText>
        </button>
      </div>
    </div>
    <Modal
      id="replays"
      :title="'REPLAYS'"
      :open="openScoreModal"
      size="md"
      headerColor="red"
      :keepratio="true"
      @close-modal="openScoreModal = false"
    >
      <template slot="modal_content">
        <ScoreVideo :match="match" :score="scoreGlobal" :booking="booking" />
      </template>
    </Modal>
  </div>
</template>

<script>
import ScoreVideo from "./ScoreVideo";
import Modal from "@/components/molecules/Modal";

export default {
  name: "ScoreDetail",
  data() {
    return {
      scoreDetail: {},
      openScoreModal: false,
      open: false,
      score_global_set: {
        equipe_1: 0,
        equipe_2: 0,
      },
    };
  },
  props: {
    match: Object,
    booking: Object,
    index: Number,
  },
  components: {
    Modal,
    ScoreVideo,
  },
  computed: {
    tempsForts() {
      return Object.values(this.match.temps_forts);
    },
    teamOne() {
      return this.tempsForts.filter((tempsFort) => tempsFort.equipe === 1);
    },
    teamTwo() {
      return this.tempsForts.filter((tempsFort) => tempsFort.equipe === 2);
    },
    point() {
      let point = 0;
      for (let i = 0; i < this.tempsForts.length; i++) {
        if (this.tempsForts[i].categorie == "but") point++;
      }
      return point;
    },
    faute() {
      return this.tempsForts.reduce(
        (acc, tempsFort) => (tempsFort.categorie === "faute" ? acc + 1 : acc),
        0
      );
    },
    scoreGlobal() {
      const lastTempsFort = this.tempsForts[this.tempsForts.length - 1];
      return lastTempsFort ? lastTempsFort.score.replace(":", " - ") : "0 - 0";
    },
    scoreGlobalSet() {
      let temps_forts = this.tempsForts.filter(
        (temps_fort) => temps_fort.categorie === "set"
      );
      temps_forts.forEach((score) => {
        if (score.scoreEquipe1 > score.scoreEquipe2)
          this.score_global_set.equipe_1 += 1;
        else if (score.scoreEquipe1 < score.scoreEquipe2)
          this.score_global_set.equipe_2 += 1;
      });
      return this.score_global_set;
    },
    duration() {
      return Math.trunc((this.match.fin - this.match.debut) / 60);
    },
  },
  methods: {
    count() {
      return Object.keys(this.booking.parties).length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables";

.bd-gray {
  color: $doi--color-color4;
}

.score {
  min-width: 160px;
}

@media screen and (max-width: 600px) {
  .line::after,
  .line::before {
    display: none;
  }

  .duration {
    font-size: $doi--text-font-size3;
  }
}
</style>
